import * as actionTypes from "../actions/actionTypes";
import { updatedObject } from "../../shared/utility";

const initialState = {
  dropdownShown: false,
  rotateMenuIcon: false,
};

const showDropDown = (state, action) => {
  return updatedObject(state, { dropdownShown: true, rotateMenuIcon: true });
};

const hideDropDown = (state, action) => {
  return updatedObject(state, { dropdownShown: false, rotateMenuIcon: false });
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SHOW_DROPDOWN:
      return showDropDown(state, action);
    case actionTypes.HIDE_DROPDOWN:
      return hideDropDown(state, action);
    default:
      return state;
  }
};

export default reducer;

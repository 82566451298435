import * as actionTypes from "../actions/actionTypes";
import { updatedObject } from "../../shared/utility";

export const initialState = {
  token: null,
  tokenExpiry: null,
  isAuthenticated: false,
  error: null,
  loading: false,
  authRedirectPath: "/",
  isAuthStateReturned: false,
  AuthAfterSessionExpired: false,
};

const authStart = (state, action) => {
  return updatedObject(state, { loading: true });
};

const authSuccess = (state, action) => {
  return updatedObject(state, {
    isAuthenticated: true,
    token: action.token,
    tokenExpiry: action.expiry,
    error: null,
    loading: false,
  });
};

const authFail = (state, action) => {
  return updatedObject(state, {
    isAuthenticated: false,
    token: null,
    tokenExpiry: null,
    error: action.error,
    loading: false,
  });
};

const authComplete = (state, action) => {
  return updatedObject(state, { loading: false });
};

const setAuthRedirectPath = (state, action) => {
  return updatedObject(state, { authRedirectPath: action.path });
};

const authCheckComplete = (state, action) => {
  return updatedObject(state, { isAuthStateReturned: true });
};

const authLogoutComplete = (state, action) => {
  return updatedObject(state, {
    isAuthenticated: false,
    token: null,
    tokenExpiry: null,
  });
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.AUTH_START:
      return authStart(state, action);
    case actionTypes.AUTH_SUCCESS:
      return authSuccess(state, action);
    case actionTypes.AUTH_FAIL:
      return authFail(state, action);
    case actionTypes.AUTH_COMPLETE:
      return authComplete(state, action);
    case actionTypes.SET_AUTH_REDIRECT_PATH:
      return setAuthRedirectPath(state, action);
    case actionTypes.AUTH_CHECK_COMPLETE:
      return authCheckComplete(state, action);
    case actionTypes.AUTH_LOGOUT_COMPLETE:
      return authLogoutComplete(state, action);
    default:
      return state;
  }
};

export default reducer;

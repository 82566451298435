import ToolTipText from "react-stockcharts/lib/tooltip/ToolTipText";
import ToolTipTSpanLabel from "react-stockcharts/lib/tooltip/ToolTipTSpanLabel";

export const displayTextsDefaults = {
  d: "Date: ",
  o: " O: ",
  h: " H: ",
  l: " L: ",
  c: " C: ",
  v: " Vol: ",
  chg: " Chg: ",
  na: "n/a",
};

export const defaultDisplay = (props, moreProps, itemsToDisplay) => {
  /* eslint-disable */
  const {
    className,
    textFill,
    labelFill,
    onClick,
    fontFamily,
    fontSize,
    displayTexts,
  } = props;
  /* eslint-enable */

  const {
    displayDate,
    open,
    high,
    low,
    percent,
    close,
    volume,
    x,
    y,
  } = itemsToDisplay;
  return (
    <g
      className={`react-stockcharts-tooltip-hover ${className}`}
      transform={`translate(${x}, ${y})`}
      onClick={onClick}
    >
      <ToolTipText x={0} y={0} fontFamily={fontFamily} fontSize={fontSize}>
        <ToolTipTSpanLabel fill={labelFill} key="label" x={0} dy="5">
          {displayTexts.d}
        </ToolTipTSpanLabel>
        <tspan key="value" fill={textFill}>
          {displayDate}
        </tspan>
        <ToolTipTSpanLabel fill={labelFill} key="label_O">
          {displayTexts.o}
        </ToolTipTSpanLabel>
        <tspan key="value_O" fill={close > open ? "#7ce56d" : "#f88e86"}>
          {open}
        </tspan>
        <ToolTipTSpanLabel fill={labelFill} key="label_H">
          {displayTexts.h}
        </ToolTipTSpanLabel>
        <tspan key="value_H" fill={close > open ? "#7ce56d" : "#f88e86"}>
          {high}
        </tspan>
        <ToolTipTSpanLabel fill={labelFill} key="label_L">
          {displayTexts.l}
        </ToolTipTSpanLabel>
        <tspan key="value_L" fill={close > open ? "#7ce56d" : "#f88e86"}>
          {low}
        </tspan>
        <ToolTipTSpanLabel fill={labelFill} key="label_C">
          {displayTexts.c}
        </ToolTipTSpanLabel>
        <tspan key="value_C" fill={close > open ? "#7ce56d" : "#f88e86"}>
          {close}
        </tspan>
        <ToolTipTSpanLabel fill={labelFill} key="label_Vol">
          {displayTexts.v}
        </ToolTipTSpanLabel>
        <tspan key="value_Vol" fill={close > open ? "#7ce56d" : "#f88e86"}>
          {volume}
        </tspan>
        <ToolTipTSpanLabel fill={labelFill} key="label_Chg">
          {displayTexts.chg}
        </ToolTipTSpanLabel>
        <tspan key="value_Chg" fill={close > open ? "#7ce56d" : "#f88e86"}>
          {percent}
        </tspan>
      </ToolTipText>
    </g>
  );
};

export const mobileDisplay = (props, moreProps, itemsToDisplay) => {
  // display OHLC only
  /* eslint-disable */
  const {
    className,
    textFill,
    labelFill,
    onClick,
    fontFamily,
    fontSize,
    displayTexts,
  } = props;
  /* eslint-enable */

  const { open, high, low, close, x, y } = itemsToDisplay;
  return (
    <g
      className={`react-stockcharts-tooltip-hover ${className}`}
      transform={`translate(${x}, ${y})`}
      onClick={onClick}
    >
      <ToolTipText x={0} y={0} fontFamily={fontFamily} fontSize={fontSize}>
        <ToolTipTSpanLabel fill={labelFill} key="label_O">
          {displayTexts.o}
        </ToolTipTSpanLabel>
        <tspan key="value_O" fill={close > open ? "#7ce56d" : "#f88e86"}>
          {open}
        </tspan>
        <ToolTipTSpanLabel fill={labelFill} key="label_H">
          {displayTexts.h}
        </ToolTipTSpanLabel>
        <tspan key="value_H" fill={close > open ? "#7ce56d" : "#f88e86"}>
          {high}
        </tspan>
        <ToolTipTSpanLabel fill={labelFill} key="label_L">
          {displayTexts.l}
        </ToolTipTSpanLabel>
        <tspan key="value_L" fill={close > open ? "#7ce56d" : "#f88e86"}>
          {low}
        </tspan>
        <ToolTipTSpanLabel fill={labelFill} key="label_C">
          {displayTexts.c}
        </ToolTipTSpanLabel>
        <tspan key="value_C" fill={close > open ? "#7ce56d" : "#f88e86"}>
          {close}
        </tspan>
      </ToolTipText>
    </g>
  );
};

export const customDisplay = (props, moreProps, itemsToDisplay) => {
  // display volume and percent change only
  /* eslint-disable */
  const {
    className,
    textFill,
    labelFill,
    onClick,
    fontFamily,
    fontSize,
    displayTexts,
  } = props;
  /* eslint-enable */

  const { open, close, percent, volume, x, y } = itemsToDisplay;
  return (
    <g
      className={`react-stockcharts-tooltip-hover ${className}`}
      transform={`translate(${x}, ${y})`}
      onClick={onClick}
    >
      <ToolTipText x={0} y={0} fontFamily={fontFamily} fontSize={fontSize}>
        <ToolTipTSpanLabel fill={labelFill} key="label_Vol">
          {displayTexts.v}
        </ToolTipTSpanLabel>
        <tspan key="value_Vol" fill={close > open ? "#7ce56d" : "#f88e86"}>
          {volume}
        </tspan>
        <ToolTipTSpanLabel fill={labelFill} key="label_Chg">
          {displayTexts.chg}
        </ToolTipTSpanLabel>
        <tspan key="value_Chg" fill={close > open ? "#7ce56d" : "#f88e86"}>
          {percent}
        </tspan>
      </ToolTipText>
    </g>
  );
};

import * as actionTypes from "../actions/actionTypes";
import { updatedObject } from "../../shared/utility";

const initialState = {
  authModalShown: false,
  inputsReset: false,
};

const showAuthModal = (state, action) => {
  return updatedObject(state, { authModalShown: true, inputsReset: false });
};

const hideAuthModal = (state, action) => {
  return updatedObject(state, { authModalShown: false, inputsReset: true });
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SHOW_AUTH_MODAL:
      return showAuthModal(state, action);
    case actionTypes.HIDE_AUTH_MODAL:
      return hideAuthModal(state, action);
    default:
      return state;
  }
};

export default reducer;

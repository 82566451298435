import React, { Component } from "react";
import { connect } from "react-redux";

import classes from "./IndicatorsDropDown.module.css";
import Aux from "../../../../hoc/Auxiliary/Auxiliary";
import Backdrop from "../../../UI/Backdrop/Backdrop";
import ArrowLogo from "../../../Logos/ArrowLogo";
import OnLogo from "../../../Logos/OnLogo";
import * as actions from "../../../../store/actions/index";
import { INDICATORS as indicators } from "../../../../appConfigs";
import { getActiveBottomIndicators } from "../../../../containers/Chart/utils/helpers";


class IndicatorsDropDown extends Component {
  constructor(props) {
    super(props);
    this.hideDropDownHandler = this.hideDropDownHandler.bind(this);
  }

  hideDropDownHandler() {
    this.props.onHideDropdown();
  }

  toggleIndicatorHandler(event, indicator) {
    const indicatorState = Object.keys(this.props.topIndicators).includes(indicator)
      ? this.props.topIndicators[indicator]
      : this.props.bottomIndicators[indicator];
    if (Object.keys(this.props.bottomIndicators).includes(indicator)
        && indicatorState.show === false) {
      const activeBottomIndicators = getActiveBottomIndicators(this.props.bottomIndicators);
      if (activeBottomIndicators.length < this.props.maxBottomIndicators) {
        this.props.onUpdateIndicator(indicator, {show: !indicatorState.show});
      } else {
        this.props.onShowErrorModal("Maximum indicators reached. " +
          `You can display up to ${this.props.maxBottomIndicators} bottom indicators only.`);
      }
    } else {
      this.props.onUpdateIndicator(indicator, {show: !indicatorState.show});
    }
  }

  changeIndicatorHandler(event, indicator) {
    const indicatorState = Object.keys(this.props.topIndicators).includes(indicator)
      ? this.props.topIndicators[indicator]
      : this.props.bottomIndicators[indicator];
    if (indicator !== "24h Volume") {
      const button = event.target.innerText;
      if (button === "Diff") {
        this.props.onUpdateIndicator(indicator, {
          show: true,
          showDiff: indicatorState.show === false ? true : !indicatorState.showDiff
        });
      } else if (["Base", "Quote"].includes(button)) {
        this.props.onUpdateIndicator(indicator, {
          show: true,
          type: button
        });
      }
    }
  }

  render() {
    return (
      <Aux>
        <Backdrop
          show={this.props.showDropdown}
          clicked={this.hideDropDownHandler}
          addClass={classes.Backdrop}
        />
        <div className={`${classes.Indicators} ${this.props.showDropdown && classes.show}`}>
          {indicators.map((indicator, index) => {
            const indicatorState = Object.keys(this.props.topIndicators).includes(indicator)
              ? this.props.topIndicators[indicator]
              : this.props.bottomIndicators[indicator];
            return (
              <div
                key={index}
                className={`${classes.Indicator} 
                            ${indicator === "Volume" && classes.LastTopIndicator} 
                            ${indicatorState.show && classes.Active}`}
              >
                <div className={classes.IndicatorName}>{indicator}</div>
                {diffCondition(indicator) &&
                  <div className={classes.DiffButtons}>
                    <button
                      className={`${indicatorState.show && indicatorState.showDiff && classes.Active}`}
                      onClick={e => this.changeIndicatorHandler(e, indicator)}
                    >
                      Diff
                    </button>
                  </div>}
                {indicator.search("Volume") !== -1 &&
                  <div className={classes.BaseQuoteButtons}>
                    <button
                      className={`${indicatorState.show && indicatorState.type === "Base" && classes.Active}`}
                      onClick={e => this.changeIndicatorHandler(e, indicator)}
                    >
                      Base
                    </button>
                    <button
                      className={`${indicatorState.show && indicatorState.type === "Quote" && classes.Active}`}
                      onClick={e => this.changeIndicatorHandler(e, indicator)}
                    >
                      Quote
                    </button>
                  </div>}
                <div className={classes.Buttons}>
                  <button onClick={() => {
                  }}>
                    <ArrowLogo/>
                  </button>
                  <button onClick={() => {
                  }}>
                    <ArrowLogo className={classes.ArrowDown}/>
                  </button>
                  <button
                    className={`${indicatorState.show && classes.Active}`}
                    onClick={e => this.toggleIndicatorHandler(e, indicator)}
                  >
                    <OnLogo/>
                  </button>
                </div>
              </div>
            )
          })}
        </div>
      </Aux>
    );
  }
}

const mapStateToProps = state => {
  return {
    showDropdown: state.chart.showIndicatorsDropdown,
    topIndicators: state.chart.topIndicators,
    bottomIndicators: state.chart.bottomIndicators,
    maxBottomIndicators: state.chart.maxBottomIndicators
  };
};

const mapDispatchToProps = dispatch => {
  return {
    onHideDropdown: () => {
      dispatch(actions.hideIndicatorsDropdown());
    },
    onUpdateIndicator: (indicator, payload) => {
      dispatch(actions.updateIndicator(indicator, payload));
    },
    onShowErrorModal: errorMsg => {
      dispatch(actions.showErrorModal(errorMsg));
    },
  };
};

const diffCondition = ind => {
  return ind.search("Buy/Sell Volume") !== -1 || ind.search("Pending Volume") !== -1;
};

export default connect(mapStateToProps, mapDispatchToProps)(IndicatorsDropDown);

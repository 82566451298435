import React from "react";
import { format } from "d3-format";
import { timeFormat } from "d3-time-format";
import { Chart } from "react-stockcharts";
import { BollingerSeries, LineSeries, RSISeries } from "react-stockcharts/lib/series";
import { XAxis, YAxis } from "react-stockcharts/lib/axes";
import { MouseCoordinateX, MouseCoordinateY } from "react-stockcharts/lib/coordinates";
import { BollingerBandTooltip, MovingAverageTooltip, RSITooltip } from "react-stockcharts/lib/tooltip";

import { chartDimensions, fontFamily } from "../utils/viewportConfigs";
import { bbCalc, emaCalc, rsiCalc } from "../utils/indicators";

const bbAppearance = {
  stroke: {
    top: "#DCDCDC",
    middle: "#01C5C4",
    bottom: "#DCDCDC",
  },
  fill: "transparent",
};


const indicatorChart = ({ id, indicator, bottomIndicator, height, origin, yExtentsIndicator,
                          viewportWidth, emaPeriod, showLast }) => {
  const yExtents = [d => [d.high, d.low]];
  const ema = emaCalc(emaPeriod);
  const rsi = rsiCalc();
  const bb = bbCalc();
  const { xAxis, yAxis, tooltip } = chartDimensions(viewportWidth);

  return (
    <Chart
      id={id}
      key={id}
      height={height}
      yExtents={indicator === "RSI" ? [0, 100] : [...yExtents, ...yExtentsIndicator]}
      padding={indicator === "RSI" ? 0 : {top: 10, bottom: 20}}
      origin={origin ? origin : [0, 0]}
    >
      {bottomIndicator &&
        <XAxis
          axisAt="bottom"
          orient="bottom"
          showTicks={showLast}
          ticks={xAxis.ticks}
          outerTickSize={0}
          strokeWidth={1}
          stroke={showLast ? "#bbbfca" : "#7F7F80"}
          tickStroke={showLast ? "#bbbfca" : "#e8e8e8"}
          fontSize={xAxis.fontSize}
          fontFamily={fontFamily}
        />}
      {indicator === "RSI" &&
        <YAxis
          axisAt="left"
          orient="left"
          ticks={0}
          stroke="#7F7F80"
          tickStroke="#bbbfca"
        />}
      {bottomIndicator &&
        <YAxis
          axisAt="right"
          orient="right"
          ticks={3}
          stroke="#7F7F80"
          tickStroke="#bbbfca"
          fontSize={yAxis.fontSize}
          // tickFormat={}
          tickValues={indicator === "RSI" ? [30, 50, 70] : []}
          fontFamily={fontFamily}
        />}
      {showLast &&
        <MouseCoordinateX
          at="bottom"
          orient="bottom"
          displayFormat={timeFormat("%Y-%m-%d %H:%M:%S")}
          fontSize={xAxis.fontSize}
        />}
      {bottomIndicator &&
        <MouseCoordinateY
          at="right"
          orient="right"
          displayFormat={format(".2f")}
        />}

      {indicator === "ema" &&
      <LineSeries yAccessor={ema.accessor()} stroke="#D0D105"/>}
      {indicator === "bb" &&
      <BollingerSeries yAccessor={bb.accessor()} {...bbAppearance} />}
      {indicator === "RSI" &&
      <RSISeries yAccessor={d => d.rsi} />}

      {indicator === "bb" &&
        <BollingerBandTooltip
          origin={viewportWidth < 600 ? [-40, 15] : [-40, 20]}
          yAccessor={bb.accessor()}
          textFill="#e8e8e8"
          labelFill="#01C5C4"
          fontFamily={fontFamily}
          fontSize={tooltip.fontSize}
          options={bb.options()}
        />}
      {indicator === "ema" &&
        <MovingAverageTooltip
          onClick={e => console.log(e)}
          origin={viewportWidth < 600 ? [-38, 35] : [-38, 25]}
          textFill={"#D0D105"}
          labelFill={"#e8e8e8"}
          fontFamily={fontFamily}
          fontSize={tooltip.fontSize}
          options={[
            {
              yAccessor: ema.accessor(),
              type: ema.type(),
              stroke: "#D0D105",
              ...ema.options(),
            },
          ]}
        />}
      {indicator === "RSI" &&
        <RSITooltip
          origin={[5, 15]}
          yAccessor={rsi.accessor()}
          options={rsi.options()}
          textFill={"#01C5C4"}
          labelFill={"#e8e8e8"}
        />}
    </Chart>
  );
}

export default indicatorChart;

import React from "react";
import { timeFormat } from "d3-time-format";
import { Chart } from "react-stockcharts";
import { BarSeries, GroupedBarSeries } from "react-stockcharts/lib/series";
import { XAxis, YAxis } from "react-stockcharts/lib/axes";
import { MouseCoordinateX, MouseCoordinateY } from "react-stockcharts/lib/coordinates";
import { GroupTooltip, SingleValueTooltip } from "react-stockcharts/lib/tooltip";

import { getVolumeKeyName } from "../utils/helpers";
import { chartDimensions, fontFamily } from "../utils/viewportConfigs";
import { formatCurrency } from "../../../shared/utility";


const barChart = ({ id, volumeType, height, origin,
                    barFill, yLabel, viewportWidth, showLast, showDiff }) => {
  const volume = getVolumeKeyName(volumeType);
  let yExtents = typeof volume === "object"
    ? d => [d[volume.buy], d[volume.sell]]
    : [d => d[volume]];
  let yAccessor = typeof volume === "object"
    ? [d => d[volume.buy], d => d[volume.sell]]
    : d => d[volume];
  const chartPadding = volumeType === "volume" ? 0 : {top: 2, bottom: 2};
  const { xAxis, yAxis, tooltip, indicator } = chartDimensions(viewportWidth);
  const showVolumeCond = ["Base Volume", "Quote Volume"].includes(volumeType);

  return (
    <Chart
      id={id}
      key={id}
      height={height}
      yExtents={yExtents}
      padding={chartPadding}
      origin={origin}
    >
      {!showVolumeCond &&
        <XAxis
          axisAt="bottom"
          orient="bottom"
          showTicks={showLast}
          ticks={xAxis.ticks}
          outerTickSize={0}
          strokeWidth={1}
          stroke={showLast ? "#bbbfca" : "#7F7F80"}
          tickStroke={"#bbbfca"}
          fontSize={xAxis.fontSize}
          fontFamily={fontFamily}
        />}
      {!showDiff && !showVolumeCond &&
        <YAxis
          axisAt="left"
          orient="left"
          ticks={0}
          stroke="#7F7F80"
          tickStroke="#bbbfca"
        />}
      <YAxis
        axisAt={showVolumeCond ? "left" : "right"}
        orient={showVolumeCond ? "left" : "right"}
        ticks={showVolumeCond ? indicator.yAxis.ticks : 3}
        stroke="#7F7F80"
        tickStroke="#bbbfca"
        fontSize={yAxis.fontSize}
        tickFormat={showVolumeCond ? indicator.yAxis.tickFormat : formatCurrency}
        fontFamily={fontFamily}
      />
      {showLast &&
        <MouseCoordinateX
          at="bottom"
          orient="bottom"
          displayFormat={timeFormat("%Y-%m-%d %H:%M:%S")}
          fontSize={xAxis.fontSize}
        />}
      <MouseCoordinateY
        at={showVolumeCond ? "left" : "right"}
        orient={showVolumeCond ? "left" : "right"}
        displayFormat={showVolumeCond ? indicator.mouseY.tickFormat : formatCurrency}
        fontSize={yAxis.fontSize}
        arrowWidth={0}
        rectHeight={18}
      />

      {["Base Volume", "Quote Volume", "Trade Count"].includes(volumeType)
        ? <BarSeries
            yAccessor={yAccessor}
            fill={d => (d.close > d.open ? "#004D40" : "#782A28")}
            opacity={volumeType === "Trade Count" ? 0.9 : 0.8}
            stroke={true}
            widthRatio={0.9}
          />
        : <GroupedBarSeries
            yAccessor={yAccessor}
            spaceBetweenBar={1}
            fill={barFill}
            opacity={1}
          />}

      {volumeType === "Trade Count" &&
        <SingleValueTooltip
          onClick={e => console.log(e)}
          origin={[5, 15]}
          yLabel={"Trades"}
          yAccessor={yAccessor}
          yDisplayFormat={formatCurrency}
          labelFill={"#e8e8e8"}
          valueFill={"#66c0b7"}
          fontSize={tooltip.fontSize}
          fontFamily={fontFamily}
        />}

      {!["Base Volume", "Quote Volume", "Trade Count"].includes(volumeType) &&
        <GroupTooltip
          onClick={e => console.log(e)}
          origin={[5, 15]}
          fontFamily={fontFamily}
          fontSize={tooltip.fontSize}
          layout="vertical"
          displayFormat={formatCurrency}
          options={[
            {
              yLabel: `Buy ${yLabel}`,
              yAccessor: d => d[volume.buy],
              labelFill: "#e8e8e8",
              valueFill: "#66c0b7",
            },
            {
              yLabel: `Sell ${yLabel}`,
              yAccessor: d => d[volume.sell],
              labelFill: "#e8e8e8",
              valueFill: "#f88e86",
            },
          ]}
        />}
    </Chart>
  );
}

export default barChart;

import React, { Component } from "react";

import classes from "./Trade.module.css";
import Layout from "../../hoc/Layout/Layout";

class Trade extends Component {
  render() {
    return (
      <Layout>
        <p style={{ textAlign: "center", color: "white" }}>Trading platform under construction</p>
      </Layout>
    );
  }
}

export default Trade

import React, { Component } from "react";
import ReactDOM from "react-dom";

import classes from "./Tooltip.module.css";


class Tooltip extends Component {

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps.children !== this.props.children
        && this.props.children === "Change Symbol") {
      const tooltipRect = ReactDOM.findDOMNode(this.tooltip).getBoundingClientRect();
      this.tooltipWidth = tooltipRect.width;
    }
  }

  render() {
    const xLocation = this.props.children === "Change Symbol"
      ? this.props.xLocation - (this.tooltipWidth / 2)
      : this.props.xLocation;

    return (
      <div
        className={`${classes.Tooltip} ${this.props.show && classes.show} ${this.props.addClass}`}
        style={{ top: `${this.props.yLocation}px`, left: `${xLocation}px` }}
        ref={elt => this.tooltip = elt}
      >
        <p>{this.props.children}</p>
      </div>
    );
  }
}

export default Tooltip;

import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { timeFormat } from "d3-time-format";
import { set } from "d3-collection";
import { scaleOrdinal } from "d3-scale";
import { ChartCanvas } from "react-stockcharts";
import { fitWidth } from "react-stockcharts/lib/helper";
import { CrossHairCursor } from "react-stockcharts/lib/coordinates";
import { last } from "react-stockcharts/lib/utils";

import priceChart from "./subcharts/priceChart";
import { chartDimensions, viewportConfigs } from "./utils/viewportConfigs";
import {
  getMaxUndefined, processPlotData, formatTopIndicators, formatBottomIndicators,
  getActiveBottomIndicators, getPriceDecimals, getPriceChartFraction,
} from "./utils/helpers";
import { emaCalc, bbCalc, rsiCalc } from "./utils/indicators";
import * as actions from "../../store/actions/index";


class ChartComponent extends Component {
  constructor(props) {
    super(props);
    const { data: inputData } = props;
    const lengthToShow = viewportConfigs(window.innerWidth)["lengthToShow"];
    const maxWindowSize = getMaxUndefined([emaCalc(8)]) + 12; // 12 added to cover bb;
    const dataToProcess = inputData.slice(-lengthToShow - maxWindowSize);
    const indicators = [emaCalc(8), bbCalc(), rsiCalc()];
    const options = { data: dataToProcess, indicators, lengthToShow };
    const { data: linearData, xScale, xAccessor, displayXAccessor } = processPlotData(options);

    this.state = {
      indicators,
      canvasHeight: window.innerHeight,
      viewportWidth: window.innerWidth,
      lengthToShow,
      linearData,
      moreData: null,
      data: linearData,
      xScale,
      xAccessor,
      displayXAccessor,
    };
    this.handleDownloadMore = this.handleDownloadMore.bind(this);
  }

  componentDidMount() {
    window.addEventListener("resize", this.updateDimensions);
    this.setState({
      ...this.state,
      suffix: 1,
    });
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.updateDimensions);
  }

  shouldComponentUpdate(nextProps, nextState, nextContext) {
    return !(this.props.symbol !== nextProps.symbol && this.props.data === nextProps.data);
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.data !== this.props.data) {
      if (
        prevProps.data[0].timeframe !== this.props.data[0].timeframe ||
        prevProps.data[0].symbol !== this.props.data[0].symbol
      ) {
        const { data: inputData } = this.props;
        const lengthToShow = viewportConfigs(window.innerWidth)["lengthToShow"];
        const maxWindowSize = getMaxUndefined([emaCalc(8)]) + 12; // 12 added to cover bb;
        const dataToProcess = inputData.slice(-lengthToShow - maxWindowSize);
        const options = { data: dataToProcess, indicators: [emaCalc(8), bbCalc()], lengthToShow };
        const { data: linearData, xScale, xAccessor, displayXAccessor } = processPlotData(options);
        this.setState({ ...this.state, data: linearData, xScale, xAccessor, displayXAccessor,
          suffix: this.state.suffix + 1, });
      }
    }

    if (
      prevProps.additionalData !== this.props.additionalData &&
      this.props.additionalData
    ) {
      // console.log("[Chart] Did Update with additionalData");
      this.setState({ ...this.state, moreData: this.props.additionalData });
      this.props.onConcatData(this.props.additionalData);
    }

    if (prevState.moreData !== this.state.moreData && this.state.moreData) {
      // console.log("[Chart] Did Update with moreData & mergedData");
      // Continue handleDownloadMore Logic here
      const start = this.props.start;
      const end = this.props.end;
      const { data: recentData, indicators } = this.state;
      const inputData = this.props.mergedData;
      const rowsToAdd = end - Math.ceil(start);
      const maxWindowSize = getMaxUndefined([emaCalc(8)]) + 12;
      const dataToProcess = inputData.slice(
        -rowsToAdd - maxWindowSize - recentData.length,
        -recentData.length
      );
      const options = { data: dataToProcess, initialIndex: start, indicators, rowsToAdd, recentData };
      const { data: linearData, xScale, xAccessor, displayXAccessor } = processPlotData(options);
      this.setState({ data: linearData, xScale, xAccessor, displayXAccessor });
    }

    if (
      prevProps.newDataReceived !== this.props.newDataReceived &&
      this.props.newDataReceived
    ) {
      const { data: prevData, indicators } = this.state;
      const inputData = this.props.mergedData;
      const lengthToShow = prevData.length + this.props.newDataCount; // 1 for new datapoint
      const options = { data: inputData, initialIndex: this.props.start, indicators, lengthToShow };
      const { data: linearData, xScale, xAccessor, displayXAccessor } = processPlotData(options);
      this.setState({ ...this.state, data: linearData, xScale, xAccessor, displayXAccessor });
    }

    if (
      prevProps.chartReset !== this.props.chartReset &&
      this.props.chartReset
    ) {
      // CHART RESET
      const { data, indicators, lengthToShow } = this.state;
      const inputData = this.props.mergedData;
      const options = { data: inputData, indicators, lengthToShow };
      const { xAccessor } = processPlotData(options);

      const start = xAccessor(last(data));
      const end = xAccessor(data[Math.max(0, data.length - this.state.lengthToShow)]);

      this.props.onResetXAxis([start, end]);
      this.props.onResetChart(false);
      this.setState({ ...this.state, suffix: this.state.suffix + 1 });
    }
  }

  updateDimensions() {
    this.setState({
      ...this.state,
      canvasHeight: window.innerHeight,
      viewportWidth: window.innerWidth,
      lengthToShow: viewportConfigs(window.innerWidth)["lengthToShow"],
    });
  }

  handleDownloadMore(start, end) {
    this.props.onInitializeResetChartState();
    this.props.onSendBounds(start, end); // save start & end bounds in redux

    if (Math.ceil(start) === end) return;
    const { data: recentData, indicators } = this.state;
    let { data: inputData } = this.props;
    if (inputData.length === recentData.length) return;

    const rowsToAdd = end - Math.ceil(start);
    const maxWindowSize = getMaxUndefined([emaCalc(8)]) + 12;
    const totalRequiredSize = rowsToAdd + maxWindowSize + recentData.length;

    if (inputData.length >= totalRequiredSize) {
      // Data already available
      const dataToProcess = inputData.slice(
        -rowsToAdd - maxWindowSize - recentData.length,
        -recentData.length
      );
      const options = { data: dataToProcess, initialIndex: start, indicators, rowsToAdd, recentData };
      const { data: linearData, xScale, xAccessor, displayXAccessor } = processPlotData(options);
      this.setState({ data: linearData, xScale, xAccessor, displayXAccessor });
    } else {
      // Request more data
      const endTime = inputData[0].date;
      const endTimestamp = timeFormat("%s")(endTime)
      this.props.onFetchMoreData(
        this.props.symbol,
        this.props.timeframe,
        endTimestamp,
        rowsToAdd
      );
      // Continue Logic in ComponentDidUpdate with moreData state
    }
  }

  render() {
    const { type, width, ratio } = this.props;
    const { data, xScale, xAccessor, displayXAccessor } = this.state;
    const priceDecimals = getPriceDecimals(this.props.symbol, this.props.priceDecimals);
    const { canvasHeightOffset, canvasMargin } = chartDimensions(this.props.viewportWidth);
    const activeBottomIndicators = getActiveBottomIndicators(this.props.bottomIndicators);
    const priceChartFraction = getPriceChartFraction(activeBottomIndicators);
    const indicatorChartFraction = 1 - priceChartFraction;

    const canvasHeight = this.state.canvasHeight - canvasHeightOffset;
    const effectiveCanvasHeight = 0.94 * canvasHeight;
    const priceChartHeight = priceChartFraction * effectiveCanvasHeight;
    const volumeChartHeight = 0.1125 * canvasHeight;
    const indicatorChartHeight =
      (indicatorChartFraction / activeBottomIndicators.length) * effectiveCanvasHeight;

    const xExtentsConfig = this.props.xExtents
      ? { xExtents: this.props.xExtents }
      : {};

    const latestClosePrice = data.slice(-1)[0].close;
    document.title = `${this.props.symbol} ${latestClosePrice} - Ladybrand`;

    const setColorScale = scaleOrdinal()
      .domain(set(data.map(d => d.region)))
      .range(["#00695f", "#922820"]);
    const fill = (d, i) => setColorScale(i);

    const topIndicators = formatTopIndicators({
      priceChartHeight,
      volumeChartHeight,
      viewportWidth: this.props.viewportWidth,
      topIndicatorsState: this.props.topIndicators
    });

    const bottomIndicators = formatBottomIndicators({
      topIndicators,
      priceChartHeight,
      indicatorChartHeight,
      activeBottomIndicators,
      barFill: fill,
      viewportWidth: this.props.viewportWidth,
      bottomIndicatorsState: this.props.bottomIndicators
    });

    return (
      <ChartCanvas
        ratio={ratio}
        width={width}
        height={canvasHeight}
        margin={canvasMargin}
        type={type}
        seriesName={`${this.props.symbol} ${this.state.suffix}`}
        data={data}
        xScale={xScale}
        {...xExtentsConfig}
        xAccessor={xAccessor}
        displayXAccessor={displayXAccessor}
        onLoadMore={this.handleDownloadMore}
      >
        {priceChart({
          id: 100,
          height: priceChartHeight,
          yExtentsIndicator: [emaCalc(8).accessor(), bbCalc().accessor()],
          viewportWidth: this.props.viewportWidth,
          showLast: !activeBottomIndicators.length,
          priceDecimals
        })}
        {topIndicators}
        {bottomIndicators.indicators}
        {bottomIndicators.diffIndicators}
        <CrossHairCursor stroke="#758696" opacity={0.8} />
      </ChartCanvas>
    );
  }
}

ChartComponent.propTypes = {
  data: PropTypes.array.isRequired,
  width: PropTypes.number.isRequired,
  ratio: PropTypes.number.isRequired,
  type: PropTypes.oneOf(["svg", "hybrid"]).isRequired,
};

ChartComponent.defaultProps = {
  type: "hybrid",
};

ChartComponent = fitWidth(ChartComponent);

const mapStateToProps = state => {
  return {
    viewportWidth: state.app.viewportWidth,
    symbol:state.chart.symbol,
    timeframe: state.chart.timeframe,
    additionalData: state.chart.additionalData,
    mergedData: state.chart.data,
    start: state.chart.startBound,
    end: state.chart.endBound,
    newDataReceived: state.chart.newDataReceived,
    newDataCount: state.chart.newDataCount,
    chartReset: state.chart.chartReset,
    xExtents: state.chart.xExtents,
    topIndicators: state.chart.topIndicators,
    bottomIndicators: state.chart.bottomIndicators,
    maxBottomIndicators: state.chart.maxBottomIndicators,
    priceDecimals: state.chart.priceDecimals,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    onFetchMoreData: (symbol, timeframe, endTime, limit) => {
      dispatch(actions.fetchMoreData(symbol, timeframe, endTime, limit));
    },
    onConcatData: moreData => {
      dispatch(actions.concatData(moreData));
    },
    onSendBounds: (start, end) => {
      dispatch(actions.moreDataRange(start, end));
    },
    onInitializeResetChartState: () => {
      dispatch(actions.initializeResetChartState());
    },
    onResetXAxis: xExtents => {
      dispatch(actions.resetXAxis(xExtents));
    },
    onResetChart: reset => {
      dispatch(actions.resetChart(reset));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ChartComponent);

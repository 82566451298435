import React, { Component } from "react";
import { connect } from "react-redux";

import classes from "./NavigationItems.module.css";
import ChartLogo from "../../Logos/ChartLogo";
import NavigationItem from "./NavigationItem/NavigationItem";
import Aux from "../../../hoc/Auxiliary/Auxiliary";
import * as actions from "../../../store/actions/index";

class NavigationItems extends Component {
  navClickedHandler = () => {
    if (!this.props.isAuthenticated) {
      this.props.onShowAuthModal();
    }
  };

  render() {
    const loginNav = (
      <NavigationItem link="/" onClick={this.props.onShowAuthModal}>
        Login
      </NavigationItem>
    );
    const logoutNav = <NavigationItem link="/logout">Logout</NavigationItem>;
    const chartNav = (
      <NavigationItem link="/chart" onClick={this.navClickedHandler}>
        <div className={classes.Chart}>
          <ChartLogo />
          <div className={classes.ChartName}>Chart</div>
        </div>
      </NavigationItem>
    );

    const tradeNav = (
      <NavigationItem link="/algotrade" onClick={this.navClickedHandler}>
        AlgoTrade
      </NavigationItem>
    );

    return (
      <ul className={classes.NavigationItems}>
        {this.props.isAuthenticated ? (
          <Aux>
            {logoutNav}
            {chartNav}
            {tradeNav}
          </Aux>
        ) : (
          <Aux>
            {loginNav}
            {chartNav}
            {tradeNav}
          </Aux>
        )}
      </ul>
    );
  }
}

const mapStateToProps = state => {
  return {
    isAuthenticated: state.auth.isAuthenticated,
    authModalShown: state.authModal.authModalShown,
    inputsReset: state.authModal.inputsReset,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    onShowAuthModal: () => {
      dispatch(actions.showAuthModal());
    },
    onHideAuthModal: () => {
      dispatch(actions.hideAuthModal());
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(NavigationItems);

import React from "react";

import classes from "./Loading.module.css";
import Spinner from "../UI/Spinner/Spinner";

const loading = props => {
  return (
    <div className={classes.Loading}>
      <Spinner addClass={classes.Spinner} />
    </div>
  );
};

export default loading;

import numeral from "numeral";
import { timeFormat } from "d3-time-format";

import * as actionTypes from "../actions/actionTypes";
import { updatedObject } from "../../shared/utility";

export const initialState = {
  symbol: "BTCUSDT",
  timeframe: "5m",

  data: [],
  reqError: false,
  resReceived: false,
  firstFetch: true,

  additionalData: [],
  startBound: null,
  endBound: null,

  newDataReceived: false,
  newDataCount: 250,

  chartReset: false,
  xExtents: null,

  loadingSymbols: false,
  symbols: null,
  priceDecimals: null,
  reqSymbolsError: false,
  symbolsReceived: false,
  showSymbolsDropdown: false,
  showIndicatorsDropdown: false,

  topIndicators: {
    "EMA": { show: true, name: "ema" },
    "Bollinger Bands": { show: true, name: "bb" },
    "Volume": { show: true, type: "Quote" },
  },

  bottomIndicators: {
    "RSI": { show: false },
    "Buy/Sell Volume": { show: true, type: "Quote", showDiff: true, yLabel: "Vol" },
  },

  maxBottomIndicators: 3,
};

const fetchDataStart = (state, action) => {
  return updatedObject(state, {
    reqError: false,
    resReceived: false,
    firstFetch: action.firstFetch,
  });
};

const fetchDataSuccess = (state, action) => {
  return updatedObject(state, {
    data: [...action.data],
    reqError: false,
    resReceived: true,
  });
};

const fetchDataFail = (state, action) => {
  return updatedObject(state, { reqError: true, resReceived: true });
};

const fetchMoreDataStart = (state, action) => {
  return updatedObject(state, { chartReset: false });
};

const fetchMoreDataSuccess = (state, action) => {
  return updatedObject(state, {
    additionalData: [...action.data],
  });
};

// TODO: handle fetchMoreDataFail

const concatData = (state, action) => {
  return updatedObject(state, { data: [...action.moreData, ...state.data] });
};

const moreDataRange = (state, action) => {
  return updatedObject(state, {
    startBound: action.start,
    endBound: action.end,
  });
};

const addLiveDataStart = (state, action) => {
  return updatedObject(state, {
    newDataReceived: false,
    chartReset: false,
  });
};

const addLiveDataSuccess = (state, action) => {
  const currentData = state.data;
  const newData = action.liveData;
  const latestCurrentDataTime = numeral(timeFormat("%s")(currentData.slice(-1)[0].date)).value();
  const latestNewDataTime = numeral(timeFormat("%s")(newData.slice(-1)[0].date)).value();

  if (latestNewDataTime > latestCurrentDataTime) {
    return updatedObject(state, {
      data: [...state.data, ...newData],
      newDataReceived: true,
      newDataCount: newData.length,
    });
  } else {
    return state;
  }
};

const changeSymbol = (state, action) => {
  return updatedObject(state, { symbol: action.symbol });
}

const changeTimeframe = (state, action) => {
  return updatedObject(state, { timeframe: action.timeframe });
};

const resetXAxis = (state, action) => {
  return updatedObject(state, {
    xExtents: action.xExtents,
  });
};

const resetChart = (state, action) => {
  return updatedObject(state, {
    chartReset: action.reset,
  });
};

const initializeResetChartState = (state, action) => {
  return updatedObject(state, { chartReset: false, xExtents: null });
};

const showSymbolsDropdown = (state, action) => {
  return updatedObject(state, { showSymbolsDropdown: true });
};

const hideSymbolsDropdown = (state, action) => {
  return updatedObject(state, { showSymbolsDropdown: false });
};

const showIndicatorsDropdown = (state, action) => {
  return updatedObject(state, { showIndicatorsDropdown: true });
};

const hideIndicatorsDropdown = (state, action) => {
  return updatedObject(state, { showIndicatorsDropdown: false });
};

const fetchSymbolsStart = (state, action) => {
  return updatedObject(state, {
    loadingSymbols: true,
    reqSymbolsError: false,
    symbolsReceived: false,
  });
};

const fetchSymbolsSuccess = (state, action) => {
  return updatedObject(state, {
    loadingSymbols: false,
    symbols: action.symbols.map(rec => rec["symbol"]),
    priceDecimals: action.symbols,
    reqSymbolsError: false,
    symbolsReceived: true,
  });
};

const fetchSymbolsFail = (state, action) => {
  return updatedObject(state, {
    loadingSymbols: false,
    reqSymbolsError: true,
    symbolsReceived: false,
  });
};

const updateIndicator = (state, action) => {
  const indicatorsKey = Object.keys(state.topIndicators).includes(action.indicator)
    ? "topIndicators"
    : "bottomIndicators";
  return updatedObject(state, {
    [indicatorsKey]: updatedObject(state[indicatorsKey], {
      [action.indicator]: updatedObject(state[indicatorsKey][action.indicator], action.payload)
    })
  });
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.FETCH_DATA_START: return fetchDataStart(state, action);
    case actionTypes.FETCH_DATA_SUCCESS: return fetchDataSuccess(state, action);
    case actionTypes.FETCH_DATA_FAIL: return fetchDataFail(state, action);
    case actionTypes.FETCH_MORE_DATA_START: return fetchMoreDataStart(state, action);
    case actionTypes.FETCH_MORE_DATA_SUCCESS: return fetchMoreDataSuccess(state, action);
    case actionTypes.CONCAT_DATA: return concatData(state, action);
    case actionTypes.MORE_DATA_RANGE: return moreDataRange(state, action);
    case actionTypes.ADD_LIVE_DATA_START: return addLiveDataStart(state, action);
    case actionTypes.ADD_LIVE_DATA_SUCCESS: return addLiveDataSuccess(state, action);
    case actionTypes.CHANGE_SYMBOL: return changeSymbol(state, action);
    case actionTypes.CHANGE_TIMEFRAME: return changeTimeframe(state, action);
    case actionTypes.RESET_XAXIS: return resetXAxis(state, action);
    case actionTypes.RESET_CHART: return resetChart(state, action);
    case actionTypes.INITIALIZE_RESET_CHART_STATE: return initializeResetChartState(state, action);
    case actionTypes.FETCH_SYMBOLS_START: return fetchSymbolsStart(state, action);
    case actionTypes.FETCH_SYMBOLS_SUCCESS: return fetchSymbolsSuccess(state, action);
    case actionTypes.FETCH_SYMBOLS_FAIL: return fetchSymbolsFail(state, action);
    case actionTypes.SHOW_SYMBOLS_DROPDOWN: return showSymbolsDropdown(state, action);
    case actionTypes.HIDE_SYMBOLS_DROPDOWN: return hideSymbolsDropdown(state, action);
    case actionTypes.SHOW_INDICATORS_DROPDOWN: return showIndicatorsDropdown(state, action);
    case actionTypes.HIDE_INDICATORS_DROPDOWN: return hideIndicatorsDropdown(state, action);
    case actionTypes.UPDATE_INDICATOR: return updateIndicator(state, action);
    default: return state;
  }
};

export default reducer;

import numeral from "numeral";
import { format } from "d3-format";

import { getPriceDecimals } from "../../../../containers/Chart/utils/helpers";

export const sortTableData = (defaultTableData, sortBy, sortOrder, inputData) => {
  let sortedTableData = [];
  if (inputData === "") {
    sortedTableData = defaultTableData;
  } else {
    defaultTableData.forEach(rec => {
      if (rec.symbol.search(inputData) !== -1) {
        sortedTableData.push(rec);
      }
    });
  }

  if (sortBy === "default") {
    return sortedTableData;
  } else {
    if (sortOrder === "ascend") {
      return [...sortedTableData].sort((a, b) => (a[sortBy] > b[sortBy]) ? 1 : -1);
    } else {
      return [...sortedTableData].sort((a, b) => (a[sortBy] < b[sortBy]) ? 1 : -1);
    }
  }
};

export const handleLiveData = (data, symbols) => {
  const tableData = [];
  data.forEach(rec => {
    const { s: symbol, c: price, P: change24, q: volume24, n: trades24 } = rec;
    if (symbols.includes(symbol)) {
     tableData.push({
       symbol,
       price: numeral(price).value(),
       change24: numeral(change24).value(),
       trades24: numeral(trades24).value(),
       volume24: numeral(volume24).value(),
     });
    }
  });
  return tableData;
};

export const formatTableData = (data, priceDecimalsState) => {
  return data.map(d => {
    const priceDecimals = getPriceDecimals(d.symbol, priceDecimalsState);
    return {
      symbol: d.symbol,
      price: format(`,.${priceDecimals}f`)(numeral(d.price).value()),
      change24: numeral(d.change24).format("0.00") + "%",
      trades24: format(",")(numeral(d.trades24).value()),
      volume24: formatQuantity(numeral(d.volume24).value()),
    };
  });
};

const formatQuantity = num => {
  if (num >= 1e9) return format(",.2f")(num / 1e6) + "M";
  if (num >= 1e6) return format(",.2f")(num / 1e6) + "M";
  if (num < 1e6) return format(",.2f")(num);
};

export const tableColumns = [
  { Header: "Symbol", accessor: "symbol" },
  { Header: "Last Price", accessor: "price" },
  { Header: "24h Change", accessor: "change24" },
  { Header: "24h Trades", accessor: "trades24" },
  { Header: "24h Volume", accessor: "volume24" },
];

export const tableData = [
  {
    symbol: "BTCUSDT",
    price: formatQuantity(60199),
    change24: numeral(0.45).format("0.00") + "%",
    trades24: formatQuantity(2086210),
    volume24: formatQuantity(3807668443)
  },
  {
    symbol: "ETHUSDT",
    price: formatQuantity(1875),
    change24: numeral(-0.51).format("0.00") + "%",
    trades24: formatQuantity(1068259),
    volume24: formatQuantity(1410735175)
  },
  {
    symbol: "BNBUSDT",
    price: formatQuantity(60199),
    change24: numeral(0.45).format("0.00") + "%",
    trades24: formatQuantity(2086210),
    volume24: formatQuantity(3807668443)
  },
];

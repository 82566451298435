import React from "react";
import { NavLink } from "react-router-dom";

import classes from "./HomeNavItem.module.css";
import HomeLogo from "../../../Logos/HomeLogo";

const homeNavItem = props => (
  <div className={classes.homeNavItem}>
    <NavLink to="/" exact>
      <HomeLogo />
      {props.children}
    </NavLink>
  </div>
);

export default homeNavItem;

export const BACKEND_HOSTS = {
  production: 'https://bot.ladybrand.net',
  development: 'http://127.0.0.1:5000',
};

export const TOKEN_KEYNAME = 'kt3Gh6sWop4asAs2nfOf9ndklsE8HbskjE4RJns7d';
export const EXPIRY_KEYNAME = 'Jsj2Tjs3kNbo6wVwkl8dYjsPqk2Nqdhf3moQ5djdl';

export const INDICATORS = [
  'EMA',
  'RSI',
  'Bollinger Bands',
  'Volume',
  'Buy/Sell Volume',
  // "24h Volume", "Pending Volume", "Trade Count", "24h Trade Count",
];

import React, { Component } from "react";
import { connect } from "react-redux";

import classes from "./SideDrawer.module.css";
import Backdrop from "../../UI/Backdrop/Backdrop";
import NavigationItem from "../NavigationItems/NavigationItem/NavigationItem";
import Aux from "../../../hoc/Auxiliary/Auxiliary";
import * as actions from "../../../store/actions/index";

class SideDrawer extends Component {
  navClickHandler = () => {
    if (!this.props.isAuthenticated) {
      this.props.onShowAuthModal();
    }
    this.props.onHideSideDrawer();
    this.props.onToggleMenuIcon();
  };
  backdropclickHandler = () => {
    this.props.onHideSideDrawer();
    this.props.onToggleMenuIcon();
  };

  render() {
    let links = ["Login", "Chart", "AlgoTrade"];
    if (this.props.isAuthenticated) {
      if (window.location.pathname === "/chart") {
        links = ["Home", "AlgoTrade", "Logout"];
      } else {
        links = ["Chart", "AlgoTrade", "Logout"];
      }
    }
    return (
      <Aux>
        <Backdrop
          show={this.props.sidedrawerShown}
          clicked={this.backdropclickHandler}
          addClass={`${classes.Backdrop} ${
            window.location.pathname === "/chart" && classes.Chart
          }`}
        />
        <ul
          className={`${classes.SideDrawer} ${
            window.location.pathname === "/chart" && classes.Chart
          } ${!this.props.sidedrawerShown && classes.hide}`}
        >
          {links.map((link, index) => (
            <NavigationItem
              key={index}
              addClass={classes.MobileNav}
              link={`/${link === "Home" ? "" : link.toLowerCase()}`}
              onClick={this.navClickHandler}
            >
              {link}
            </NavigationItem>
          ))}
        </ul>
      </Aux>
    );
  }
}

const mapStateToProps = state => {
  return {
    isAuthenticated: state.auth.isAuthenticated,
    sidedrawerShown: state.sidedrawer.sidedrawerShown,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    onShowSideDrawer: () => {
      dispatch(actions.showSideDrawer());
    },
    onHideSideDrawer: () => {
      dispatch(actions.hideSideDrawer());
    },
    onToggleMenuIcon: () => {
      dispatch(actions.toggleMenuIcon());
    },
    onShowAuthModal: () => {
      dispatch(actions.showAuthModal());
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SideDrawer);

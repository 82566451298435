import React, { Component } from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import { connect } from "react-redux";

import "./App.css";
import Home from "./components/Home/Home";
import Chart from "./containers/Chart/index";
import Trade from "./containers/Trade/Trade";
import Account from "./containers/Account/Account";
import Logout from "./containers/Auth/Logout/Logout";
import Loading from "./components/Loading/Loading";
import Error404 from "./components/Errors/Error404/Error404";
import ViewPort from "./shared/viewport";
import Aux from "./hoc/Auxiliary/Auxiliary";
import * as actions from "./store/actions/index";

class App extends Component {
  componentDidMount() {
    // console.log("[App] Did Mount");
    this.props.onAuthCheck();
  }

  shouldComponentUpdate(nextProps, nextState) {
    if (
      !nextProps.isAuthenticated &&
      nextProps.authError === "session expired"
    ) {
      return false;
    } else if (
      nextProps.isAuthenticated &&
      this.props.authError === "session expired"
    ) {
      return false;
    } else return nextProps.viewportWidth === this.props.viewportWidth;
  }

  render() {
    let routes = (
      // unauthenticated routes
      <Switch>
        <Route path="/" exact component={Home} />
        <Redirect to="/" />
      </Switch>
    );

    if (this.props.isAuthenticated) {
      routes = (
        // authenticated routes
        <Switch>
          <Route path="/chart" component={Chart} />
          <Route path="/algotrade" component={Trade} />
          <Route path="/account" component={Account} />
          <Route path="/logout" component={Logout} />
          <Route path="/" exact component={Home} />
          <Route render={() => <Error404 />} />
        </Switch>
      );
    }

    return (
      <Aux>
        {this.props.isAuthStateReturned ? (
          <div className="App">{routes}</div>
        ) : (
          <Loading />
        )}
        <ViewPort />
      </Aux>
    );
  }
}

const mapStateToProps = state => {
  return {
    viewportWidth: state.app.viewportWidth,
    isAuthenticated: state.auth.isAuthenticated,
    isAuthStateReturned: state.auth.isAuthStateReturned,
    authError: state.auth.error,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    onAuthCheck: () => {
      dispatch(actions.authCheckState());
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(App);

import numeral from 'numeral';
import { isoParse, timeFormat, timeParse } from 'd3-time-format';
import axios from 'axios';
import { BACKEND_HOSTS, TOKEN_KEYNAME } from '../../../appConfigs';
import { volumeSchema } from '../configs/schemas';

export const timedeltas = { '1m': 1, '5m': 5, '15m': 15, '1h': 60 }; // min

export const host =
  BACKEND_HOSTS[
    process.env.NODE_ENV === 'development' ? 'development' : 'production'
  ];

export const getPrice = async ({
  symbol,
  timeframe,
  limit,
  startTime,
  endTime,
}) => {
  /**
   * get OHLCV data
   * @param {string} endtime   timestamp in secs
   * @param {string} symbol    coin pair
   * @param {string} timeframe 5m, 15m, 1h
   * @param {int}    limit     number of data points returned
   */
  const url = 'https://api.binance.com/api/v3/klines';

  let params = {
    symbol: symbol,
    interval: timeframe,
    limit: limit,
  };
  if (startTime) {
    params = {
      symbol: symbol,
      interval: timeframe,
      startTime: startTime * 1000,
    };
  }
  if (endTime) {
    params = {
      symbol: symbol,
      interval: timeframe,
      limit: limit,
      endTime: endTime * 1000,
    };
  }

  if (startTime && endTime) {
    params = {
      symbol: symbol,
      interval: timeframe,
      startTime: startTime * 1000,
      endTime: endTime * 1000,
    };
  }

  return axios
    .get(url, { params: params })
    .then(response => {
      if (startTime && endTime) {
        return formatResponse(response.data, 'price');
      } else {
        response.data.pop(); // remove partial candlestick
        return formatResponse(response.data, 'price');
      }
    })
    .catch(error => {
      return {
        message: error.response.data.message,
        status: error.response.status,
      };
    });
};

export const getVolumes = async ({
  symbol,
  timeframe,
  limit,
  startTime,
  endTime,
}) => {
  /**
   * get pending and 24h volumes
   * @param {string} endtime    timestamp in secs
   * @param {int}    limit      number of data points returned
   * @param {string} timeframe  5m, 15m, 1h
   */
  const url = `${host}/api/volumes`;
  const token = sessionStorage.getItem(TOKEN_KEYNAME);
  const headers = { Authorization: `Bearer ${token}` };
  let params = { symbol: symbol, timeframe: timeframe, limit: limit };
  if (startTime) {
    params = { ...params, startTime };
  }
  if (endTime) {
    params = { ...params, endTime };
  }
  const options = { params, headers };
  return axios
    .get(url, options)
    .then(response => {
      return formatResponse(response.data, 'volumes');
    })
    .catch(error => {
      // console.log(error.response.status);
      // console.log(error.response.data.message);
      return {
        message: error.response.data.message,
        status: error.response.status,
      };
    });
};

export const formatResponse = (resp, datatype) => {
  return resp.map(record => {
    // open time for all cases
    switch (datatype) {
      case 'price':
        const volume = numeral(record[5]).value();
        const quoteVolume = numeral(record[7]).value();
        const buyVolume = numeral(record[9]).value();
        const buyQuoteVolume = numeral(record[10]).value();
        const sellVolume = volume - buyVolume;
        const sellQuoteVolume = quoteVolume - buyQuoteVolume;
        return {
          date: timeParse('%s')(record[0] / 1000),
          timestampPrice: record[0] / 1000,
          open: numeral(record[1]).value(),
          high: numeral(record[2]).value(),
          low: numeral(record[3]).value(),
          close: numeral(record[4]).value(),
          trades: numeral(record[8]).value(),
          volume,
          quoteVolume,
          buyVolume,
          sellVolume,
          buyQuoteVolume,
          sellQuoteVolume,
        };
      case 'volumes':
        return {
          timestampVol: numeral(timeFormat('%s')(isoParse(record.t))).value(),
          volume24: numeral(record.v24).value(),
          trades24: numeral(record.tct).value(),
          pendingBuyVolume: numeral(record.bpd).value(),
          pendingSellVolume: numeral(record.spd).value(),
          pendingBuyQuoteVol: numeral(record.bpdQt).value(),
          pendingSellQuoteVol: numeral(record.spdQt).value(),
        };
      default:
        return null;
    }
  });
};

export const mergeData = (symbol, timeframe, ohlc, volumes) => {
  return ohlc.map(record => {
    const volumeRecord = volumes.find(
      vol => vol.timestampVol === record.timestampPrice
    );
    if (volumeRecord) {
      return {
        symbol,
        timeframe,
        ...record,
        ...volumeRecord,
      };
    } else
      return {
        symbol,
        timeframe,
        ...record,
        ...volumeSchema,
      };
  });
};

import React from "react";
import { NavLink } from "react-router-dom";

import classes from "./Error404.module.css";

const error404 = props => {
  return (
    <div className={classes.Error404}>
      <p style={{ color: "#fd5e53", fontSize: "50px" }}>{props.error || 404}</p>
      <p>{props.errorMessage || "Sorry. Page Not Found."}</p>
      <div className={classes.Link}>
        <NavLink to="/">Home</NavLink>
      </div>
    </div>
  );
};

export default error404;

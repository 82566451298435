import { bollingerBand, ema, rsi } from "react-stockcharts/lib/indicator";

export const emaCalc = period => ema()
  .id(0)
  .options({
    windowSize: period,
  })
  .merge((d, c) => {
    d[`ema${period}`] = c;
  })
  .accessor(d => d[`ema${period}`]);

export const bbCalc = () => bollingerBand()
  .id(1)
  .merge((d, c) => {
    d.bb = c;
  })
  .accessor(d => d.bb);

export const rsiCalc = () => rsi()
  .options({ windowSize: 14 })
  .merge((d, c) => {d.rsi = c;})
  .accessor(d => d.rsi);

import * as actionTypes from "./actionTypes";
import { authComplete } from "../actions/auth";
import { sleep } from "../../shared/utility";

export const showAuthModal = () => {
  return {
    type: actionTypes.SHOW_AUTH_MODAL,
  };
};

export const hideAuthModal = () => {
  return async dispatch => {
    dispatch({
      type: actionTypes.HIDE_AUTH_MODAL,
    });
    await sleep(100);
    dispatch(authComplete());
  };
};

import numeral from "numeral";
import { timeParse } from "d3-time-format";
import { discontinuousTimeScaleProviderBuilder } from "react-stockcharts/lib/scale";

import lineChart from "../subcharts/lineChart";
import barChart from "../subcharts/barChart";
import indicatorChart from "../subcharts/indicatorChart";
import { bbCalc, emaCalc } from "./indicators";


export const getMaxUndefined = calculators => {
  return calculators
    .map(each => each.undefinedLength())
    .reduce((a, b) => Math.max(a, b));
};

export const getPriceDecimals = (symbol, priceDecimals) => {
  return priceDecimals.find(dec => dec.symbol === symbol)["price decimals"];
};

export const getVolumeKeyName = volumeType => {
  switch (volumeType) {
    case "Quote Volume":
      return "quoteVolume";
    case "Base Volume":
      return "volume";
    case "Quote 24h Volume":
      return "volume24";
    case "Base Buy/Sell Volume":
      return { buy: "buyVolume", sell: "sellVolume" };
    case "Quote Buy/Sell Volume":
      return { buy: "buyQuoteVolume", sell: "sellQuoteVolume" };
    case "Base Pending Volume":
      return { buy: "pendingBuyVolume", sell: "pendingSellVolume" };
    case "Quote Pending Volume":
      return { buy: "pendingBuyQuoteVol", sell: "pendingSellQuoteVol" };
    case "Trade Count":
      return "trades";
    case "24h Trade Count":
      return "trades24";
    default:
      return "volume";
  }
};

export const processPlotData = ({ data, initialIndex, indicators, lengthToShow,
                                  rowsToAdd, recentData }) => {
  // const processedData = ema8(bb(data));
  const processedData = assessIndicators(data, indicators);
  const indexCalculator = initialIndex
    ? discontinuousTimeScaleProviderBuilder().initialIndex(Math.ceil(initialIndex)).indexCalculator()
    : discontinuousTimeScaleProviderBuilder().indexCalculator();
  const { index } = rowsToAdd
    ? indexCalculator(processedData.slice(-rowsToAdd).concat(recentData))
    : indexCalculator(processedData);

  const xScaleProvider = rowsToAdd
    ? discontinuousTimeScaleProviderBuilder().initialIndex(Math.ceil(initialIndex)).withIndex(index)
    : discontinuousTimeScaleProviderBuilder().withIndex(index);

  return rowsToAdd
    ? xScaleProvider(processedData.slice(-rowsToAdd).concat(recentData))
    : xScaleProvider(processedData.slice(-lengthToShow));
};

const assessIndicators = (data, indicators) => {
  if (!indicators) {
    return data;
  }
  let processedData = null;
  indicators.forEach(indicator => {
    processedData = indicator(data);
  })
  return processedData;
};

export const formatTopIndicators = ({ topIndicatorsState,
                                      priceChartHeight, volumeChartHeight,
                                      viewportWidth }) => {
  return Object.keys(topIndicatorsState)
    // eslint-disable-next-line array-callback-return
    .map((indicatorName, index) => {
      const indicator = topIndicatorsState[indicatorName];
      if (indicator.show) {
        if (indicatorName === "Volume") {
          return barChart({
            id: index,
            volumeType: `${indicator.type} ${indicatorName}`,
            height: volumeChartHeight,
            origin: (w, h) => [0, priceChartHeight - volumeChartHeight],
            viewportWidth
          })
        } else {
          return indicatorChart({
            id: index,
            indicator: indicator.name,
            height: priceChartHeight,
            yExtentsIndicator: [emaCalc(8).accessor(), bbCalc().accessor()],
            viewportWidth,
            emaPeriod: indicator.name === "ema" && 8
          });
        }
      }
    });
};

export const formatBottomIndicators = ({ bottomIndicatorsState, topIndicators,
                                         priceChartHeight, indicatorChartHeight,
                                         viewportWidth, barFill, activeBottomIndicators }) => {
  let bottomIndex = 0;
  const diffIndicators = [];
  const bottomIndicators = [];
  Object.keys(bottomIndicatorsState)
    .forEach((indicatorName, index) => {
      const indicator = bottomIndicatorsState[indicatorName];
      if (indicator.show) {
        if (["24h Volume", "24h Trade Count"].includes(indicatorName)) {
          const volumeType = indicatorName === "24h Volume"
            ? `${indicator.type} ${indicatorName}`
            : indicatorName;
          const chart = lineChart({
            id: topIndicators.length + bottomIndex + 1,
            volumeType,
            height: indicatorChartHeight,
            origin: [0, priceChartHeight + bottomIndex * indicatorChartHeight],
            yLabel: indicator.yLabel,
            viewportWidth,
            showLast: bottomIndex === activeBottomIndicators.length - 1
          });
          bottomIndicators.push(chart);
          bottomIndex++;
        } else if (indicatorName === "RSI") {
          const chart = indicatorChart({
            id: topIndicators.length + bottomIndex + 1,
            indicator: indicatorName,
            bottomIndicator: true,
            height: indicatorChartHeight,
            origin: [0, priceChartHeight + bottomIndex * indicatorChartHeight],
            viewportWidth,
            showLast: bottomIndex === activeBottomIndicators.length - 1
          });
          bottomIndicators.push(chart);
          bottomIndex++;
        } else {
          const chart = barChart({
            id: topIndicators.length + bottomIndex + 1,
            volumeType: indicatorName === "Trade Count"
              ? `${indicatorName}`
              : `${indicator.type} ${indicatorName}`,
            height: indicatorChartHeight,
            origin: [0, priceChartHeight + bottomIndex * indicatorChartHeight],
            barFill: barFill,
            yLabel: indicator.yLabel,
            viewportWidth,
            showLast: bottomIndex === activeBottomIndicators.length - 1,
            showDiff: indicator.showDiff
          });
          bottomIndicators.push(chart);
          if (indicator.showDiff) {
            const chart = lineChart({
              id: (topIndicators.length + bottomIndex) * 10,
              height: indicatorChartHeight,
              volumeType: `${indicator.type} ${indicatorName} Diff`,
              origin: [0, priceChartHeight + bottomIndex * indicatorChartHeight],
              viewportWidth
            });
            diffIndicators.push(chart);
          }
          bottomIndex++;
        }
      }
    });
  return { indicators: bottomIndicators, diffIndicators };
};

export const getActiveBottomIndicators = indicatorsState => {
  const activeBottomIndicators = [];
  Object.keys(indicatorsState).forEach(indicatorName => {
    if (indicatorsState[indicatorName].show) {
      activeBottomIndicators.push(indicatorName);
    }
  });
  return activeBottomIndicators;
}

export const getPriceChartFraction = activeBottomIndicators => {
  switch (activeBottomIndicators.length) {
    case 3: return 0.48;
    case 2: return 0.55;
    case 1: return 0.66;
    case 0: return 1.00;
    default: return 0.48;
  }
}

export const handleLiveData = (data, dispatch) => {
  const { k: ticks } = data;
  const volume = numeral(ticks.v).value();
  const quoteVolume = numeral(ticks.q).value();
  const buyVolume = numeral(ticks.V).value();
  const buyQuoteVolume = numeral(ticks.Q).value();
  const sellVolume = volume - buyVolume;
  const sellQuoteVolume = quoteVolume - buyQuoteVolume;
  const symbol = ticks.s;
  const timeframe = ticks.i;
  const isFinal = ticks.x;

  if (isFinal) {
    const priceData = {
      date: timeParse("%s")(ticks.t / 1000), // open time
      timestampPrice: ticks.t / 1000,
      open: numeral(ticks.o).value(),
      high: numeral(ticks.h).value(),
      low: numeral(ticks.l).value(),
      close: numeral(ticks.c).value(),
      trades: numeral(ticks.n).value(),
      volume,
      quoteVolume,
      buyVolume,
      sellVolume,
      buyQuoteVolume,
      sellQuoteVolume,
    };
    dispatch(priceData, symbol, timeframe);
  }
};

import React, { Component } from "react";
import { connect } from "react-redux";
import { timeFormat } from "d3-time-format";
import numeral from "numeral";

import classes from "./Chart.module.css";
import Layout from "../../hoc/Layout/Layout";
import ChartComponent from "./Chart";
import Error404 from "../../components/Errors/Error404/Error404";
import Loading from "../../components/Loading/Loading";
import Aux from "../../hoc/Auxiliary/Auxiliary";
import Backdrop from "../../components/UI/Backdrop/Backdrop";
import SymbolsDropDown from "../../components/Navigation/DropDowns/Symbols/SymbolsDropDown";
import IndicatorsDropDown from "../../components/Navigation/DropDowns/Indicators/IndicatorsDropDown";
import ErrorModal from "../../components/Errors/ErrorModal/ErrorModal";
import * as actions from "../../store/actions/index";
import { handleLiveData } from "./utils/helpers";
import { noop } from "../../shared/utility";


class Chart extends Component {
  state = {
    reload: false,
  };

  connectSocket = (symbol, timeframe) => {
    // console.log(`${symbol} ${timeframe} websocket connected`);
    const streams = [`${symbol.toLowerCase()}@kline_${timeframe}`].join("/");
    const connection = btoa(streams);
    this[connection] = new WebSocket(`wss://stream.binance.com:9443/stream?streams=${streams}`);

    this[connection].onmessage = evt => {
      const eventData = JSON.parse(evt.data);
      handleLiveData(eventData.data, this.props.onAddLiveData);
    };
  }

  disconnectSocket = (symbol, timeframe) => {
    // console.log(`${symbol} ${timeframe} websocket disconnected`);
    const streams = [`${symbol.toLowerCase()}@kline_${timeframe}`].join("/");
    const connection = btoa(streams);
    if (this[connection].readyState === WebSocket.OPEN) {
      this[connection].close();
    }
  };

  socketConnected = (symbol, timeframe) => {
    const streams = [`${symbol.toLowerCase()}@kline_${timeframe}`].join("/");
    const connection = btoa(streams);
    return this[connection].readyState === WebSocket.OPEN;
  };

  componentDidMount() {
    // console.log("[ChartIndex] Did Mount");
    this.connectSocket(this.props.symbol, this.props.timeframe);
    this.props.onAuthCheck();
    this.props.onFetchData(this.props.symbol, this.props.timeframe, true);
  }

  componentWillUnmount() {
    this.disconnectSocket(this.props.symbol, this.props.timeframe);
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.symbol !== this.props.symbol) {
      this.disconnectSocket(prevProps.symbol, this.props.timeframe);
      this.connectSocket(this.props.symbol, this.props.timeframe);
      this.props.onFetchData(this.props.symbol, this.props.timeframe, false);
    }
    if (prevProps.timeframe !== this.props.timeframe) {
      this.disconnectSocket(this.props.symbol, prevProps.timeframe);
      this.connectSocket(this.props.symbol, this.props.timeframe);
      this.props.onFetchData(this.props.symbol, this.props.timeframe, false);
    }
    if (prevProps.isAuthenticated !== this.props.isAuthenticated
        && !this.props.isAuthenticated) {
      this.disconnectSocket(this.props.symbol, this.props.timeframe);
    }
    if (prevProps.chartReset !== this.props.chartReset &&
        this.props.chartReset) {
      // Chart Reset
      if (!this.socketConnected(this.props.symbol, this.props.timeframe)) {
        this.connectSocket(this.props.symbol, this.props.timeframe);
      }
    }
    if (
      prevProps.authError === "session expired" &&
      this.props.isAuthenticated
    ) {
      window.location.reload();
    }
    if (
      prevProps.tokenExpiry !== this.props.tokenExpiry &&
      this.props.tokenExpiry
    ) {
      // window.location.reload();
      this.setState({ reload: true }, () => {
        this.setState({ reload: false });
      });
    }
    if (prevState.reload !== this.state.reload && this.state.reload) {
      window.location.reload();
    }
  }

  render() {
    const currentTime = numeral(timeFormat("%s")(new Date())).value(); // secs
    let chart;
    if (this.props.firstFetch) {
      if (!this.props.resReceived || !this.props.symbolsReceived) {
        chart = <Loading/>;
      } else if (this.props.reqError) {
        if (!this.props.isAuthenticated || currentTime > this.props.tokenExpiry) {
          chart = null;
        } else if (this.props.isAuthenticated) {
          chart = <Loading/>;
        } else {
          chart = (
            <Error404 error={400} errorMessage="Sorry. Something went wrong."/>
          );
        }
      } else {
        chart = (
          <div className={classes.Chart}>
            <ChartComponent data={this.props.data}/>
          </div>
        );
      }
    } else {
      chart = (
        <div className={classes.Chart}>
          <ChartComponent data={this.props.data}/>
        </div>
      );
    }
    const sessionExpired = currentTime > this.props.tokenExpiry
      || this.props.authError === "session expired";
    const authShow = !this.props.isAuthenticated
      || currentTime > this.props.tokenExpiry
      || this.props.authError === "session expired";

    return (
      <Aux>
        <SymbolsDropDown />
        <IndicatorsDropDown />
        <ErrorModal />
        <Backdrop
          show={!this.props.resReceived && !this.props.firstFetch}
          clicked={noop}
          addClass={classes.LoadingBackdrop}
        />
        <Layout
          show={authShow}
          sessionExpired={sessionExpired}
          modalClosed={noop}
          onKeyDown={noop}
          resetInputs={this.props.inputsReset}
          redirectPath="/chart"
          Chart
        >
          {chart}
        </Layout>
      </Aux>
    );
  }
}

const mapStateToProps = state => {
  return {
    symbol: state.chart.symbol,
    timeframe: state.chart.timeframe,
    isAuthenticated: state.auth.isAuthenticated,
    isAuthStateReturned: state.auth.isAuthStateReturned,
    token: state.auth.token,
    tokenExpiry: numeral(state.auth.tokenExpiry).value(),
    authError: state.auth.error,
    inputsReset: state.authModal.inputsReset,
    data: state.chart.data,
    reqError: state.chart.reqError,
    resReceived: state.chart.resReceived,
    chartReset: state.chart.chartReset,
    firstFetch: state.chart.firstFetch,
    symbolsReceived: state.chart.symbolsReceived,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    onAuthCheck: () => {
      dispatch(actions.authCheckState());
    },
    onFetchData: (symbol, timeframe, firstFetch) => {
      dispatch(actions.fetchData(symbol, timeframe, firstFetch));
    },
    onAddLiveData: (priceDataPoint, symbol, timeframe) => {
      dispatch(actions.addLiveData(priceDataPoint, symbol, timeframe));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Chart);

import * as actionTypes from "./actionTypes";

export const showSideDrawer = () => {
  return {
    type: actionTypes.SHOW_SIDEDRAWER,
  };
};

export const hideSideDrawer = () => {
  return {
    type: actionTypes.HIDE_SIDEDRAWER,
  };
};

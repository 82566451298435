import React, { Component } from "react";
import { Redirect } from "react-router-dom";
import { connect } from "react-redux";

import classes from "./Home.module.css";
import Layout from "../../hoc/Layout/Layout";
import Aux from "../../hoc/Auxiliary/Auxiliary";
import Spinner from "../UI/Spinner/Spinner";
import * as actions from "../../store/actions/index";

class Home extends Component {
  state = {
    redirect: false,
  };

  keyDownHandler = event => {
    if (event.key === "Escape") {
      this.props.onHideAuthModal();
    }
  };

  buttonClickedHandler = () => {
    if (this.props.isAuthenticated) {
      this.setState({
        ...this.state,
        redirect: true,
      });
    } else {
      this.props.onShowAuthModal();
    }
  };

  render() {
    document.title = "Bitcoin Charting Tool - Ladybrand";
    const buttonRedirect = this.state.redirect && <Redirect to="/chart" />;
    const home = (
      <Aux>
        {buttonRedirect}
        {this.props.isAuthStateReturned ? (
          <div className={classes.Home} onKeyDown={this.keyDownHandler}>
            <div className={classes.Content}>
              <p>
                {this.props.isAuthenticated
                  ? "Welcome Trader"
                  : "Automated Crypto Trading"}
              </p>
              <button onClick={this.buttonClickedHandler}>
                {this.props.isAuthenticated ? "Go to Chart" : "Get Started"}
              </button>
            </div>
          </div>
        ) : (
          <Spinner />
        )}
      </Aux>
    );
    return (
      <Layout redirectPath="/">
        {home}
      </Layout>
    );
  }
}

const mapStateToProps = state => {
  return {
    isAuthenticated: state.auth.isAuthenticated,
    isAuthStateReturned: state.auth.isAuthStateReturned,
    authModalShown: state.authModal.authModalShown,
    inputsReset: state.authModal.inputsReset,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    onShowAuthModal: () => {
      dispatch(actions.showAuthModal());
    },
    onHideAuthModal: () => {
      dispatch(actions.hideAuthModal());
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Home);

import {format} from "d3-format";

export const fontFamily = "Trebuchet MS, roboto, ubuntu, sans-serif";

const viewportFraction = innerWidth => {
  if (innerWidth < 800) {
    return 0.1;
  }
  return 0.12;
};

export const viewportConfigs = innerWidth => {
  return { lengthToShow: Math.ceil(viewportFraction(innerWidth) * innerWidth) };
};

export const chartDimensions = viewportWidth => {
  const condition = viewportWidth < 600;
  return {
    canvasHeightOffset: condition ? 58 + 3 : 55 + 26, // header and footer heights
    canvasMargin: condition
      ? { left: 45, right: 50, top: 11, bottom: 30 }
      : { left: 60, right: 70, top: 20, bottom: 30 },
    xAxis: {
      fontSize: condition ? 11 : 11,
      ticks: condition ? 4 : 10,
    },
    yAxis: {
      fontSize: condition ? 10 : 11,
      tickFormat: condition ? format(".0f") : format(".2f"),
    },
    tooltip: {
      fontSize: condition ? 11 : 12,
    },
    edgeIndicator: {
      yAxisPad: condition ? 1 : 5,
      arrowWidth: condition ? 3 : 5,
      rectHeight: condition ? 15 : 15,
    },
    volume: {
      yAxis: {
        tickFormat: condition ? format(".0s") : format(".2s"),
        ticks: condition ? 5 : 5,
      },
      mouseY: { tickFormat: condition ? format(".2s") : format(".4s") },
    },
    indicator: {
      yAxis: {
        tickFormat: condition ? format(".0s") : format(".2s"),
        ticks: condition ? 2 : 3,
      },
      mouseY: { tickFormat: condition ? format(".2s") : format(".4s") },
    },
  };
};

import React from "react";
import { NavLink } from "react-router-dom";

import classes from "./DropDown.module.css";
import Aux from "../../../hoc/Auxiliary/Auxiliary";
import Backdrop from "../../UI/Backdrop/Backdrop";

const DropDown = props => {
  return (
    <Aux>
      <Backdrop
        show={props.show}
        clicked={props.clicked}
        addClass={classes.Backdrop}
      />
      <div
        className={`${classes.DropDown} ${!props.show && classes.hide}`}
        onClick={props.clicked}
      >
        <div className={classes.Link}>
          <NavLink to="/">Home</NavLink>
        </div>
        <div className={classes.Link}>
          <NavLink to="/logout">Logout</NavLink>
        </div>
      </div>
    </Aux>
  );
};

export default DropDown;

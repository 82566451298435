import React from "react";
import { useTable } from "react-table";
import numeral from "numeral";

import classes from "./Table.module.css";
import { formatTableData } from "../utils";


const Table = props => {
  const data = formatTableData(props.data, props.priceDecimals);
  const prevData = formatTableData(props.prevData, props.priceDecimals);
  const columns = props.columns;

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
  } = useTable({ columns, data });

  const handleDataClick = (event, row) => {
    const symbol = row.cells[0].value;
    props.onSymbolClick(event, symbol);
  };

  return (
    <table {...getTableProps()} className={classes.Table} align={"center"}>
      <thead>
      {headerGroups.map(headerGroup => (
        <tr
          {...headerGroup.getHeaderGroupProps()}
          className={classes.HeaderRow}
        >
          {headerGroup.headers.map(column => {
            return (
              <th
                {...column.getHeaderProps()}
                className={`${classes.Header} ${classes[column.id]}`}
                onClick={e => props.onHeaderClick(e, column.id)}
              >
                <div className={`${classes.HeaderChild} ${classes[column.id]}`}>
                  <div className={classes.HeaderName}>{column.render("Header")}</div>
                  <div className={classes.FilterIcons}>
                    <div className={`${classes.UpTriangle} 
                                     ${props.sortBy === column.id && props.sortOrder === "ascend" && classes.Active}`}>
                      &#9650;
                    </div>
                    <div className={`${classes.DownTriangle} 
                                     ${props.sortBy === column.id && props.sortOrder === "descend" && classes.Active}`}>
                      &#9660;
                    </div>
                  </div>
                </div>
              </th>
            );
          })}
        </tr>
      ))}
      </thead>

      <tbody {...getTableBodyProps()}>
      {rows.map(row => {
        prepareRow(row);
        return (
          <tr
            {...row.getRowProps()}
            className={classes.DataRow}
            onClick={e => handleDataClick(e, row)}
          >
            {row.cells.map(cell => {
              const symbol = row.values.symbol;
              const price = numeral(row.values.price).value();
              const prevPriceRecord = prevData.find(rec => rec.symbol === symbol);
              const prevPrice = prevPriceRecord ? numeral(prevPriceRecord.price).value() : null;
              const priceColor = price > prevPrice ? "PriceUp" : "PriceDown";
              const changeColor = row.values.change24 >= "0%" ? "Change24Up" : "Change24Down";
              return (
                <td
                  {...cell.getCellProps()}
                  className={`${classes.Cell} ${classes[cell.column.id]} 
                              ${classes[changeColor]} ${classes[priceColor]}`}
                >
                  {cell.render("Cell")}
                </td>
              )
            })}
          </tr>
        );
      })}
      </tbody>
    </table>
  );
};

export default Table;

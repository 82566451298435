import React, { Component } from "react";
import { connect } from "react-redux";

import classes from "./SymbolsDropDown.module.css";
import Aux from "../../../../hoc/Auxiliary/Auxiliary";
import Table from "./Table/Table";
import Spinner from "../../../UI/Spinner/Spinner";
import Backdrop from "../../../UI/Backdrop/Backdrop";
import CrossMenuLogo from "../../../Logos/CrossMenuLogo";
import * as actions from "../../../../store/actions";
import { handleLiveData, sortTableData, tableColumns } from "./utils";


class SymbolsDropDown extends Component {
  constructor(props) {
    super(props);
    this.state = {
      tableData: [],
      prevTableData: [],
      inputData: "",
      sortBy: "default",
      sortOrder: "ascend",
      showFilter: false,
      loading: false,
    };
    this.hideDropDownHandler = this.hideDropDownHandler.bind(this);
    this.symbolSelectHandler = this.symbolSelectHandler.bind(this);
  }

  connectStreams = () => {
    if (this.connection.readyState === WebSocket.OPEN) {
      this.connection.send(JSON.stringify({
        method: "SUBSCRIBE",
        params: ["!ticker@arr"],
        id: 2,
      }));
    } else {
      this.connection = new WebSocket(`wss://stream.binance.com:9443/ws`);
      this.connection.onopen = evt => {
        this.connection.send(JSON.stringify({
          method: "SUBSCRIBE",
          params: ["!ticker@arr"],
          id: 2,
        }));
      };
    }

    this.connection.onmessage = evt => {
      const eventData = JSON.parse(evt.data);
      if (!eventData.id) {
        const prevTableData = this.state.tableData;
        let tableData = handleLiveData(eventData, this.props.symbols, this.props.priceDecimals);

        if (prevTableData.length) {
          tableData = prevTableData.map(prevRec => {
            const newRec = tableData.find(rec => rec.symbol === prevRec.symbol);
            if (newRec ) return newRec;
            return prevRec;
          });
        }
        this.setState({...this.state, tableData});
      }
    };
  }

  disconnectStreams = () => {
    this.connection.send(JSON.stringify({
      method: "UNSUBSCRIBE",
      params: ["!ticker@arr"],
      id: 2,
    }));
  }

  componentDidMount() {
    this.setState({ ...this.state, loading: true });
    this.connection = new WebSocket(`wss://stream.binance.com:9443/ws`);
    if (!this.props.symbols) {
      this.props.onFetchSymbols();
    }
  }

  componentWillUnmount() {
    this.connection.close();
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps.showSymbolsDropdown !== this.props.showSymbolsDropdown && this.props.symbols) {
      if (this.props.showSymbolsDropdown) {
        this.connectStreams();
      } else {
        this.disconnectStreams();
      }
    }

    if (prevState.tableData !== this.state.tableData
      && this.state.tableData.length !== 0
      && this.props.symbols.length !== 0) {
      this.setState({ ...this.state, loading: false });
      if (prevState.tableData.length !== 0) {
        this.setState({ ...this.state, prevTableData: prevState.tableData });
      }
    }
  }

  hideDropDownHandler() {
    this.props.onHideDropdown();
    this.setState({
      ...this.state,
      inputData: "",
      sortBy: "default",
      sortOrder: "ascend",
      showFilter: false
    });
  }

  symbolSelectHandler = (event, requestedSymbol) => {
    if (requestedSymbol !== this.props.symbol) {
      this.props.onChangeSymbol(requestedSymbol);
    }
    this.hideDropDownHandler();
  }

  headerClickHandler = (event, fieldId) => {
    if (this.state.sortBy === fieldId) {
      const sortOrder = this.state.sortOrder === "ascend" ? "descend" : "ascend";
      this.setState({ ...this.state, sortOrder });
    } else {
      this.setState({...this.state, sortBy: fieldId, sortOrder: "ascend"});
    }
  }

  inputChangeHandler = event => {
    const word = event.target.value.toUpperCase();
    this.setState({ ...this.state, inputData: word });
  }

  sortHandler = event => {
    this.setState({ ...this.state, sortBy: event.target.innerText, showFilter: false });
  }

  onSubmitHandler = event => {
    event.preventDefault();
  }

  render() {
    const sortedTableData = sortTableData(this.state.tableData, this.state.sortBy,
                                          this.state.sortOrder, this.state.inputData);

    return (
      <Aux>
        <Backdrop
          show={this.props.showSymbolsDropdown}
          clicked={this.hideDropDownHandler}
          addClass={classes.Backdrop}
        />
        <div className={`${classes.DropDown} ${this.props.showSymbolsDropdown && classes.show}`}>
          <form className={classes.SearchForm} onSubmit={this.onSubmitHandler}>
            <input
              value={this.state.inputData}
              onChange={this.inputChangeHandler}
              className={classes.SearchInput}
              type="text"
              placeholder="Search Symbol"
            />
            <CrossMenuLogo onClick={this.hideDropDownHandler} />
            <div className={`${classes.FilterOptions} ${this.state.showFilter && classes.show}`}>
              <p
                onClick={this.sortHandler}
                className={`${classes.Filter} ${this.state.sortBy === "Name" && classes.Active}`}
              >
                Name
              </p>
              <p
                onClick={this.sortHandler}
                className={`${classes.Filter} ${this.state.sortBy === "Default" && classes.Active}`}
              >
                Default
              </p>
            </div>
          </form>
          <div className={`${classes.Symbols} ${!this.state.loading && classes.NotLoading}`}>
            {this.state.loading && <Spinner addClass={classes.Spinner} />}
            {!this.state.loading && (
              <Table
                data={sortedTableData}
                columns={tableColumns}
                prevData={this.state.prevTableData}
                priceDecimals={this.props.priceDecimals}
                onSymbolClick={this.symbolSelectHandler}
                onHeaderClick={this.headerClickHandler}
                sortBy={this.state.sortBy}
                sortOrder={this.state.sortOrder}
              />
            )}
          </div>
        </div>
      </Aux>
    );
  }
}

const mapStateToProps = state => {
  return {
    loading: state.chart.loadingSymbols,
    symbol: state.chart.symbol,
    symbols: state.chart.symbols,
    priceDecimals: state.chart.priceDecimals,
    reqError: state.chart.reqSymbolsError,
    symbolsReceived: state.chart.symbolsReceived,
    showSymbolsDropdown: state.chart.showSymbolsDropdown,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    onFetchSymbols: () => {
      dispatch(actions.fetchSymbols());
    },
    onHideDropdown: () => {
      dispatch(actions.hideSymbolsDropdown());
    },
    onChangeSymbol: symbol => {
      dispatch(actions.changeSymbol(symbol));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SymbolsDropDown);

import * as actionTypes from "../actions/actionTypes";
import { updatedObject } from "../../shared/utility";


const initialState = {
  showErrorModal: false,
  errorMessage: null,
}

const showErrorModal = (state, action) => {
  return updatedObject(state, {
    showErrorModal: true,
    errorMessage: action.errorMessage
  });
};

const hideErrorModal = (state, action) => {
  return updatedObject(state, { showErrorModal: false });
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SHOW_ERROR_MODAL: return showErrorModal(state, action);
    case actionTypes.HIDE_ERROR_MODAL: return hideErrorModal(state, action);
    default: return state;
  }
};

export default reducer;

import { createStore, applyMiddleware, compose, combineReducers } from "redux";
import thunk from "redux-thunk";

import appReducer from "../reducers/app";
import authReducer from "../reducers/auth";
import authModalReducer from "../reducers/authModal";
import errorModalReducer from "../reducers/errorModal";
import dropdownReducer from "../reducers/dropdown";
import sidedrawerReducer from "../reducers/sidedrawer";
import chartReducer from "../reducers/chart";
import toolbarReducer from "../reducers/toolbar";

const storeConfig = () => {
  const rootReducer = combineReducers({
    app: appReducer,
    toolbar: toolbarReducer,
    auth: authReducer,
    authModal: authModalReducer,
    errorModal: errorModalReducer,
    dropdown: dropdownReducer,
    sidedrawer: sidedrawerReducer,
    chart: chartReducer,
  });

  const composeEnhancers =
    process.env.NODE_ENV === "development"
      ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
      : null || compose;

  const store = createStore(
    rootReducer,
    composeEnhancers(applyMiddleware(thunk))
  );

  return store;
};

export default storeConfig;

import { Component } from "react";
import { connect } from "react-redux";
import * as actions from "../store/actions/index";

class ViewPort extends Component {
  constructor(props) {
    super(props);
    this.updateDimensions = this.updateDimensions.bind(this);
  }

  componentDidMount() {
    window.addEventListener("resize", this.updateDimensions);
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.updateDimensions);
  }

  updateDimensions() {
    this.props.onSyncViewPortWidth();
  }

  render() {
    return null;
  }
}

const mapStateToProps = state => {
  return {
    viewportWidth: state.app.viewportWidth,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    onSyncViewPortWidth: () => {
      dispatch(actions.syncViewPortWidth());
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ViewPort);

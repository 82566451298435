import axios from 'axios';
import numeral from 'numeral';
import { timeFormat } from 'd3-time-format';

import * as actionTypes from './actionTypes';
import { store } from '../../index';
import { authFail } from './auth';
import { TOKEN_KEYNAME } from '../../appConfigs';
import {
  host,
  timedeltas,
  getPrice,
  getVolumes,
  mergeData,
} from '../../containers/Chart/utils/manageData';

export const fetchDataStart = firstFetch => {
  return {
    type: actionTypes.FETCH_DATA_START,
    firstFetch,
  };
};

export const fetchMoreDataStart = () => {
  return {
    type: actionTypes.FETCH_MORE_DATA_START,
  };
};

export const fetchDataSuccess = data => {
  return {
    type: actionTypes.FETCH_DATA_SUCCESS,
    data: data,
  };
};

export const fetchMoreDataSuccess = data => {
  return {
    type: actionTypes.FETCH_MORE_DATA_SUCCESS,
    data: data,
  };
};

export const fetchDataFail = () => {
  return {
    type: actionTypes.FETCH_DATA_FAIL,
  };
};

export const fetchMoreDataFail = () => {
  return {
    type: actionTypes.FETCH_MORE_DATA_FAIL,
  };
};

export const concatData = moreData => {
  return {
    type: actionTypes.CONCAT_DATA,
    moreData: moreData,
  };
};

export const moreDataRange = (start, end) => {
  return {
    type: actionTypes.MORE_DATA_RANGE,
    start,
    end,
  };
};

export const addLiveDataStart = () => {
  return {
    type: actionTypes.ADD_LIVE_DATA_START,
  };
};

export const addLiveDataSuccess = liveData => {
  return {
    type: actionTypes.ADD_LIVE_DATA_SUCCESS,
    liveData,
  };
};

export const addLiveDataFail = () => {
  return {
    type: actionTypes.ADD_LIVE_DATA_FAIL,
  };
};

export const fetchSymbolsStart = () => {
  return {
    type: actionTypes.FETCH_SYMBOLS_START,
  };
};

export const fetchSymbolsSuccess = symbols => {
  return {
    type: actionTypes.FETCH_SYMBOLS_SUCCESS,
    symbols,
  };
};

export const fetchSymbolsFail = () => {
  return {
    type: actionTypes.FETCH_SYMBOLS_FAIL,
  };
};

export const fetchData = (
  symbol,
  timeframe,
  firstFetch,
  endTime,
  limit = 300
) => {
  return dispatch => {
    dispatch(fetchDataStart(firstFetch));
    const ohlcReq = getPrice({ symbol, timeframe, limit: limit + 1, endTime });
    const requests = [ohlcReq];

    axios.all(requests).then(
      axios.spread((...responses) => {
        if (responses.some(resp => resp.status)) {
          dispatch(fetchDataFail());
          assessAuth(responses, dispatch);
        } else {
          const ohlcRes = responses[0];
          const data = ohlcRes.map(rec => ({ symbol, timeframe, ...rec }));
          dispatch(fetchDataSuccess(data));
        }
      })
    );
  };
};

export const fetchMoreData = (symbol, timeframe, endTime, limit) => {
  return dispatch => {
    dispatch(fetchMoreDataStart());
    const ohlcReq = getPrice({ symbol, timeframe, limit: limit + 1, endTime });
    const requests = [ohlcReq];

    axios.all(requests).then(
      axios.spread((...responses) => {
        if (responses.some(resp => resp.status)) {
          dispatch(fetchMoreDataFail());
          assessAuth(responses, dispatch);
        } else {
          const ohlcRes = responses[0];
          const data = ohlcRes.map(rec => ({ symbol, timeframe, ...rec }));
          dispatch(fetchMoreDataSuccess(data));
        }
      })
    );
  };
};

export const addLiveData = (priceDataPoint, symbol, timeframe) => {
  return dispatch => {
    dispatch(addLiveDataStart());
    const secondsDelta = timedeltas[timeframe] * 60;
    const currentChartData = store.getState().chart.data;
    const startOpenTime = numeral(
      timeFormat('%s')(currentChartData.slice(-1)[0].date)
    ).value();
    const endOpenTime = priceDataPoint.timestampPrice;

    const ohlcReq = getPrice({
      symbol,
      timeframe,
      startTime: startOpenTime + secondsDelta,
      endTime: endOpenTime,
    });
    const requests = [ohlcReq];

    axios.all(requests).then(
      axios.spread((...responses) => {
        if (responses.some(resp => resp.status)) {
          dispatch(addLiveDataFail());
          assessAuth(responses, dispatch);
        } else {
          const ohlcRes = responses[0];
          const data = ohlcRes.map(rec => ({ symbol, timeframe, ...rec }));
          dispatch(addLiveDataSuccess(data));
        }
      })
    );
  };
};

export const fetchSymbols = () => {
  return dispatch => {
    dispatch(fetchSymbolsStart());
    const url = `${host}/api/symbols`;
    const token = sessionStorage.getItem(TOKEN_KEYNAME);
    const headers = { Authorization: `Bearer ${token}` };
    axios
      .get(url, { headers: headers })
      .then(response => {
        const symbols = response.data;
        const usdtSymbols = [];
        symbols.forEach(symbol => {
          if (symbol.symbol.slice(-4) === 'USDT') {
            usdtSymbols.push(symbol);
          }
        });
        dispatch(fetchSymbolsSuccess(usdtSymbols));
      })
      .catch(error => {
        dispatch(fetchSymbolsFail());
      });
  };
};

export const changeSymbol = symbol => {
  return {
    type: actionTypes.CHANGE_SYMBOL,
    symbol: symbol,
  };
};

export const changeTimeframe = timeframe => {
  return {
    type: actionTypes.CHANGE_TIMEFRAME,
    timeframe: timeframe,
  };
};

export const resetXAxis = xExtents => {
  return {
    type: actionTypes.RESET_XAXIS,
    xExtents: xExtents,
  };
};

export const resetChart = reset => {
  return {
    type: actionTypes.RESET_CHART,
    reset: reset,
  };
};

export const initializeResetChartState = () => {
  return {
    type: actionTypes.INITIALIZE_RESET_CHART_STATE,
  };
};

export const showSymbolsDropdown = () => {
  return {
    type: actionTypes.SHOW_SYMBOLS_DROPDOWN,
  };
};

export const hideSymbolsDropdown = () => {
  return {
    type: actionTypes.HIDE_SYMBOLS_DROPDOWN,
  };
};

export const showIndicatorsDropdown = () => {
  return {
    type: actionTypes.SHOW_INDICATORS_DROPDOWN,
  };
};

export const hideIndicatorsDropdown = () => {
  return {
    type: actionTypes.HIDE_INDICATORS_DROPDOWN,
  };
};

export const updateIndicator = (indicator, payload) => {
  return {
    type: actionTypes.UPDATE_INDICATOR,
    indicator,
    payload,
  };
};

const assessAuth = (responses, dispatch) => {
  const currentAuthState = store.getState().auth;
  const errorMessage = responses.find(resp => resp.status).message;
  const currentTime = numeral(timeFormat('%s')(new Date())).value(); // secs
  const tokenExpiry = numeral(currentAuthState.tokenExpiry).value(); // secs
  if (errorMessage === 'session expired' || currentTime > tokenExpiry) {
    dispatch(authFail(errorMessage));
  }
};

const mergeAndDispatch = (
  symbol,
  timeframe,
  ohlcRes,
  volumesRes,
  requestAll,
  dispatch
) => {
  const mergedData = mergeData(symbol, timeframe, ohlcRes, volumesRes);
  dispatch(addLiveDataSuccess(mergedData));
  clearInterval(requestAll);
};

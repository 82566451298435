import { updatedObject } from "../../shared/utility";
import * as actionTypes from "../actions/actionTypes";

const initialState = {
  sidedrawerShown: false,
};

const showSideDrawer = (state, action) => {
  return updatedObject(state, { sidedrawerShown: true });
};

const hideSideDrawer = (state, action) => {
  return updatedObject(state, { sidedrawerShown: false });
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SHOW_SIDEDRAWER:
      return showSideDrawer(state, action);
    case actionTypes.HIDE_SIDEDRAWER:
      return hideSideDrawer(state, action);
    default:
      return state;
  }
};

export default reducer;

import React from "react";
import { connect } from "react-redux";

import classes from "./ErrorModal.module.css";
import Backdrop from "../../UI/Backdrop/Backdrop";
import Aux from "../../../hoc/Auxiliary/Auxiliary";
import * as actions from "../../../store/actions/index";


const errorModal = props => {
  return (
    <Aux>
      <Backdrop show={props.showErrorModal} clicked={props.onHideErrorModal} />
      <div className={`${classes.ErrorModal} ${props.showErrorModal && classes.show}`}>
        <p>{props.errorMessage}</p>
        <button onClick={props.onHideErrorModal}>OK</button>
      </div>
    </Aux>
  );
};

const mapStateToProps = state => {
  return {
    showErrorModal: state.errorModal.showErrorModal,
    errorMessage: state.errorModal.errorMessage,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    onHideErrorModal: () => {
      dispatch(actions.hideErrorModal());
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(errorModal);

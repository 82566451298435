import * as actionTypes from "./actionTypes";


export const showErrorModal = errorMessage => {
  return {
    type: actionTypes.SHOW_ERROR_MODAL,
    errorMessage,
  };
};

export const hideErrorModal = () => {
  return {
    type: actionTypes.HIDE_ERROR_MODAL,
  };
};

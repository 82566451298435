export const SHOW_AUTH_MODAL = "SHOW_AUTH_MODAL";
export const HIDE_AUTH_MODAL = "HIDE_AUTH_MODAL";

export const SHOW_ERROR_MODAL = "SHOW_ERROR_MODAL";
export const HIDE_ERROR_MODAL = "HIDE_ERROR_MODAL";

export const SHOW_DROPDOWN = "SHOW_DROPDOWN";
export const HIDE_DROPDOWN = "HIDE_DROPDOWN";

export const AUTH_USER = "AUTH_USER";
export const AUTH_START = "AUTH_START";
export const AUTH_SUCCESS = "AUTH_SUCCESS";
export const AUTH_FAIL = "AUTH_FAIL";
export const AUTH_COMPLETE = "AUTH_COMPLETE";
export const AUTH_CHECK_START = "AUTH_CHECK_START";
export const AUTH_CHECK_TIMEOUT = "AUTH_CHECK_TIMEOUT";
export const AUTH_CHECK_COMPLETE = "AUTH_CHECK_COMPLETE";
export const AUTH_INITIATE_LOGOUT = "AUTH_INITIATE_LOGOUT";
export const AUTH_LOGOUT_START = "AUTH_LOGOUT_START";
export const AUTH_LOGOUT_COMPLETE = "AUTH_LOGOUT_COMPLETE";

export const SET_AUTH_REDIRECT_PATH = "SET_AUTH_REDIRECT_PATH";

export const FETCH_DATA_START = "FETCH_DATA_START";
export const FETCH_DATA_SUCCESS = "FETCH_DATA_SUCCESS";
export const FETCH_DATA_FAIL = "FETCH_DATA_FAIL";

export const FETCH_MORE_DATA_START = "FETCH_MORE_DATA_START";
export const FETCH_MORE_DATA_SUCCESS = "FETCH_MORE_DATA_SUCCESS";
export const FETCH_MORE_DATA_FAIL = "FETCH_MORE_DATA_FAIL";

export const CONCAT_DATA = "CONCAT_DATA";

export const MORE_DATA_RANGE = "MORE_DATA_RANGE";

export const ADD_LIVE_DATA_START = "ADD_LIVE_DATA_START";
export const ADD_LIVE_DATA_SUCCESS = "ADD_LIVE_DATA_SUCCESS";
export const ADD_LIVE_DATA_FAIL = "ADD_LIVE_DATA_FAIL";

export const CHANGE_SYMBOL = "CHANGE_SYMBOL";
export const CHANGE_TIMEFRAME = "CHANGE_TIMEFRAME";

export const RESET_XAXIS = "RESET_XAXIS";
export const RESET_CHART = "RESET_CHART";
export const INITIALIZE_RESET_CHART_STATE = "INITIALIZE_RESET_CHART_STATE";

export const SYNC_VIEWPORT_WIDTH = "SYNC_VIEWPORT_WIDTH";

export const TOGGLE_MENU_ICON = "TOGGLE_MENU_ICON";

export const SHOW_SIDEDRAWER = "SHOW_SIDEDRAWER";
export const HIDE_SIDEDRAWER = "HIDE_SIDEDRAWER";

export const FETCH_SYMBOLS = "FETCH_SYMBOLS";
export const FETCH_SYMBOLS_START = "FETCH_SYMBOLS_START";
export const FETCH_SYMBOLS_SUCCESS = "FETCH_SYMBOLS_SUCCESS";
export const FETCH_SYMBOLS_FAIL = "FETCH_SYMBOLS_FAIL";

export const SHOW_SYMBOLS_DROPDOWN = "SHOW_SYMBOLS_DROPDOWN";
export const HIDE_SYMBOLS_DROPDOWN = "HIDE_SYMBOLS_DROPDOWN";

export const SHOW_INDICATORS_DROPDOWN = "SHOW_INDICATORS_DROPDOWN";
export const HIDE_INDICATORS_DROPDOWN = "HIDE_INDICATORS_DROPDOWN";

export const UPDATE_INDICATOR = "UPDATE_INDICATOR";

import React from "react";
import { connect } from "react-redux";

import classes from "./Footer.module.css";

const footer = props => {
  const currentYear = new Date().getFullYear();

  let footer = (
    <footer
      className={`${classes.Footer} ${props.Chart && classes.ChartFooter}`}
    >
      <a
        href="https://www.maricetech.com"
        target="_blank"
        rel="noopener noreferrer"
      >
        &copy; {currentYear} MariceTech
      </a>
    </footer>
  );

  if (props.viewportWidth < 600 && props.Chart) {
    footer = null;
  }

  return footer;
};

const mapStateToProps = state => {
  return {
    viewportWidth: state.app.viewportWidth,
  };
};

export default connect(mapStateToProps, null)(footer);

import React from "react";
import classes from "./Backdrop.module.css";

const backdrop = props => (
  <div
    className={[classes.Backdrop, props.show && classes.show, props.addClass].join(" ")}
    onClick={props.clicked}
  />
  );

export default backdrop;

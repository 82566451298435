import React from "react";
import { format } from "d3-format";
import { timeFormat } from "d3-time-format";
import { Chart } from "react-stockcharts";
import { XAxis, YAxis } from "react-stockcharts/lib/axes";
import { EdgeIndicator, MouseCoordinateX, MouseCoordinateY } from "react-stockcharts/lib/coordinates";
import { CandlestickSeries } from "react-stockcharts/lib/series";
import { OHLCTooltip } from "react-stockcharts/lib/tooltip";

import { chartDimensions, fontFamily } from "../utils/viewportConfigs";
import { customDisplay, defaultDisplay, displayTextsDefaults, mobileDisplay } from "../utils/ohlcTooltipDisplay";


const candlesAppearance = {
  wickStroke: "#B5B5B8",
  fill: d => (d.close > d.open ? "#36a845" : "#d11d17"),
  stroke: d => (d.close > d.open ? "#36A845" : "#D11D17"),
  candleStrokeWidth: 1,
  widthRatio: 0.8,
  opacity: 1,
};


const priceChart = ({ id, height, yExtentsIndicator, viewportWidth,
                    priceDecimals, showLast }) => {
  const yExtents = [d => [d.high, d.low]];
  const { xAxis, yAxis, tooltip, edgeIndicator } = chartDimensions(viewportWidth);

  return (
    <Chart
      id={id}
      height={height}
      yExtents={[...yExtents, ...yExtentsIndicator]}
      padding={{top: 10, bottom: 20}}
      origin={[0, 0]}
    >
      <XAxis
        axisAt="bottom"
        orient="bottom"
        showTicks={showLast}
        ticks={xAxis.ticks}
        outerTickSize={0}
        strokeWidth={1}
        stroke={showLast ? "#bbbfca" : "#7F7F80"}
        tickStroke={"#bbbfca"}
        fontSize={xAxis.fontSize}
        fontFamily={fontFamily}
      />
      <YAxis
        axisAt="right"
        orient="right"
        ticks={5}
        stroke="#7F7F80"
        tickStroke={"#bbbfca"}
        fontSize={yAxis.fontSize}
        tickFormat={format(`.${priceDecimals}f`)}
        fontFamily={fontFamily}
      />
      {showLast &&
        <MouseCoordinateX
          at="bottom"
          orient="bottom"
          displayFormat={timeFormat("%Y-%m-%d %H:%M:%S")}
          fontSize={xAxis.fontSize}
        />}
      <MouseCoordinateY
        at="right"
        orient="right"
        displayFormat={format(`.${priceDecimals}f`)}
        fontSize={yAxis.fontSize}
        arrowWidth={0}
        rectHeight={18}
      />

      <CandlestickSeries {...candlesAppearance} />

      <EdgeIndicator
        itemType="last"
        orient="right"
        edgeAt="right"
        yAccessor={d => d.close}
        fill={d => (d.close > d.open ? "#36A845" : "#D11D17")}
        displayFormat={format(`.${priceDecimals}f`)}
        fontSize={yAxis.fontSize}
        yAxisPad={edgeIndicator.yAxisPad}
        arrowWidth={edgeIndicator.arrowWidth}
        rectHeight={edgeIndicator.rectHeight}
      />
      <OHLCTooltip
        origin={[-40, 0]}
        textFill="#e8e8e8"
        labelFill="#01C5C4"
        fontFamily={fontFamily}
        fontSize={tooltip.fontSize}
        children={viewportWidth < 600 ? mobileDisplay : defaultDisplay}
        displayTexts={displayTextsDefaults}
      />
      {viewportWidth < 600 && (
        <OHLCTooltip
          origin={[-40, 30]}
          textFill="#e8e8e8"
          labelFill="#01C5C4"
          fontFamily={fontFamily}
          fontSize={tooltip.fontSize}
          children={customDisplay}
          displayTexts={displayTextsDefaults}
        />
      )}
    </Chart>
  );
}

export default priceChart;

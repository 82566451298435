import { updatedObject } from "../../shared/utility";
import * as actionTypes from "../actions/actionTypes";

const initialState = {
  viewportWidth: window.innerWidth,
};

const syncViewPortWidth = (state, action) => {
  return updatedObject(state, {
    viewportWidth: window.innerWidth,
  });
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SYNC_VIEWPORT_WIDTH:
      return syncViewPortWidth(state, action);
    default:
      return state;
  }
};

export default reducer;

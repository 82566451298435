import numeral from "numeral";
import { format } from "d3-format";
import { timeFormat } from "d3-time-format";

export const noop = () => {};

export const sleep = ms => new Promise(resolve => setTimeout(resolve, ms));

export const formatCurrency = amount => format(".4s")(amount).replace("G", "B");

export const updatedObject = (oldObject, updatedProperties) => {
  return {
    ...oldObject,
    ...updatedProperties,
  };
};

export const getTokenExpirationTimestamp = expiresIn => {
  // return token expiration time in secs
  const timeUnit = expiresIn.charAt(expiresIn.length - 1);
  const timeNum = parseFloat(expiresIn.split(timeUnit)[0]);
  switch (timeUnit) {
    case "h":
      return numeral(timeFormat("%s")(new Date())).value() - 5 + timeNum * 3600;
    case "m":
      return numeral(timeFormat("%s")(new Date())).value() - 5 + timeNum * 60;
    case "s":
      return numeral(timeFormat("%s")(new Date())).value() - 5 + timeNum;
    default:
      return;
  }
};

export const checkValidity = (fieldName, fieldValue) => {
  let isValid = true;
  switch (fieldName) {
    case "username":
      isValid = true;
      const usernameRegex = /^[-a-zA-Z0-9_]+$/;
      const numberRegex = /^\d+$/;
      isValid =
        fieldValue.trim().length > 3 &&
        fieldValue.trim().length < 20 &&
        isValid; // proper length
      isValid = usernameRegex.test(fieldValue) && isValid; // proper characters
      isValid = !numberRegex.test(fieldValue) && isValid; // not numeric
      break;
    case "password":
      isValid = true;
      const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/;
      isValid = passwordRegex.test(fieldValue) && isValid; // proper characters
      break;
    default:
      isValid = false;
  }
  return isValid;
};

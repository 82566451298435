import { updatedObject } from "../../shared/utility";
import * as actionTypes from "../actions/actionTypes";

const initialState = {
  menuIconClicked: false,
};

const toggleMenuIcon = (state, action) => {
  return updatedObject(state, { menuIconClicked: !state.menuIconClicked });
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.TOGGLE_MENU_ICON:
      return toggleMenuIcon(state, action);
    default:
      return state;
  }
};

export default reducer;

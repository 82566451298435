import React, { Component } from "react";
import { connect } from "react-redux";

import classes from "./MobileChartDropDown.module.css";
import Aux from "../../../../hoc/Auxiliary/Auxiliary";
import Backdrop from "../../../UI/Backdrop/Backdrop";

class MobileChartDropDown extends Component {
  state = {
    showDropDown: false,
  };

  showDropDownHandler = () => {
    this.setState({ ...this.state, showDropDown: true });
  };

  hideDropDownHandler = () => {
    this.setState({ ...this.state, showDropDown: false });
  };

  timeframeSelectHandler = event => {
    this.hideDropDownHandler();
    this.props.timeframeSelect(event);
  };

  render() {
    return (
      <Aux>
        <Backdrop
          show={this.state.showDropDown}
          clicked={this.hideDropDownHandler}
          addClass={classes.Backdrop}
        />
        <button
          onClick={this.showDropDownHandler}
          className={classes.CurrentTimeframe}
        >
          {this.props.timeframe}
        </button>
        <div
          className={`${classes.DropDown} ${
            this.state.showDropDown && classes.show
          }`}
        >
          <button
            onClick={this.timeframeSelectHandler}
            className={`${classes.Timeframe} ${
              this.props.timeframe === "5m" && classes.Active
            }`}
          >
            5m
          </button>
          <button
            onClick={this.timeframeSelectHandler}
            className={`${classes.Timeframe} ${
              this.props.timeframe === "15m" && classes.Active
            }`}
          >
            15m
          </button>
          <button
            onClick={this.timeframeSelectHandler}
            className={`${classes.Timeframe} ${
              this.props.timeframe === "1h" && classes.Active
            }`}
          >
            1h
          </button>
        </div>
      </Aux>
    );
  }
}

const mapStateToProps = state => {
  return {
    timeframe: state.chart.timeframe,
  };
};

export default connect(mapStateToProps, null)(MobileChartDropDown);

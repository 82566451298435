import React, { Component } from "react";

import classes from "./Modal.module.css";
import Aux from "../../../hoc/Auxiliary/Auxiliary";
import Backdrop from "../Backdrop/Backdrop";

class Modal extends Component {
  // Modal component and children components will update only if
  // the show property is active (i.e. when the Modal is shown
  // by clicking the GetStarted button or other auth links)
  shouldComponentUpdate(nextProps, nextState) {
    return (
      nextProps.show !== this.props.show ||
      nextProps.children !== this.props.children
    );
  }

  render() {
    return (
      <Aux>
        <Backdrop show={this.props.show} clicked={this.props.modalClosed} />
        <div className={`${classes.Modal} ${this.props.show && classes.show}`}>
          {this.props.children}
        </div>
      </Aux>
    );
  }
}

export default Modal;

import * as actionTypes from "./actionTypes";

export const showDropDown = () => {
  return {
    type: actionTypes.SHOW_DROPDOWN,
  };
};

export const hideDropDown = () => {
  return {
    type: actionTypes.HIDE_DROPDOWN,
  };
};

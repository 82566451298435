import React, { Component } from "react";
import { connect } from "react-redux";

import classes from "./Layout.module.css";
import Aux from "../Auxiliary/Auxiliary";
import AuthModal from "../../containers/Auth/AuthModal/AuthModal";
import Toolbar from "../../components/Navigation/Toolbar/Toolbar";
import Footer from "../../components/Navigation/Footer/Footer";
import DropDown from "../../components/Navigation/DropDown/DropDown";
import * as actions from "../../store/actions/index";

class Layout extends Component {
  keyDownHandler = event => {
    if (event.key === "Escape") {
      this.props.onHideAuthModal();
    }
  };

  render() {
    return (
      <Aux>
        <AuthModal
          show={this.props.show ? this.props.show : this.props.authModalShown}
          sessionExpired={this.props.sessionExpired}
          modalClosed={this.props.modalClosed ? this.props.modalClosed : this.props.onHideAuthModal}
          onKeyDown={this.props.onKeyDown ? this.props.onKeyDown : this.keyDownHandler}
          resetInputs={this.props.inputsReset}
          redirectPath={this.props.redirectPath}
        />
        <DropDown
          show={this.props.dropdownShown}
          clicked={this.props.onHideDropDown}
        />
        <Toolbar isAuth={this.props.isAuthenticated} Chart={this.props.Chart} />
        <main
          className={`${classes.Content} ${this.props.Chart && classes.Chart} ${
            this.props.viewportWidth < 600 && classes.Mobile
          }`}
        >
          {this.props.children}
        </main>
        <Footer Chart={this.props.Chart} />
      </Aux>
    );
  }
}

const mapStateToProps = state => {
  return {
    isAuthenticated: state.auth.isAuthenticated,
    authModalShown: state.authModal.authModalShown,
    inputsReset: state.authModal.inputsReset,
    dropdownShown: state.dropdown.dropdownShown,
    viewportWidth: state.app.viewportWidth,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    onShowAuthModal: () => {
      dispatch(actions.showAuthModal());
    },
    onHideAuthModal: () => {
      dispatch(actions.hideAuthModal());
    },
    onHideDropDown: () => {
      dispatch(actions.hideDropDown());
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Layout);

import React, { Component } from "react";
import { Redirect } from "react-router-dom";
import { connect } from "react-redux";

import desktopClasses from "./AuthModal.module.css";
import mobileClasses from "./MobileAuthModal.module.css";
import UserLogo from "../../../components/Logos/UserLogo";
import LockLogo from "../../../components/Logos/LockLogo";
import LoginLogo from "../../../components/Logos/LoginLogo";
import Modal from "../../../components/UI/Modal/Modal";
import Spinner from "../../../components/UI/Spinner/Spinner";
import { checkValidity } from "../../../shared/utility";
import * as actions from "../../../store/actions/index";

class AuthModal extends Component {
  state = {
    controls: {
      username: {
        type: "text",
        placeholder: "Username",
        value: "",
        valid: false,
        shouldValidate: false,
      },
      password: {
        type: "password",
        placeholder: "Password",
        value: "",
        valid: false,
        shouldValidate: false,
      },
    },
    redirectPath: "",
    validInput: true,
    homeRedirect: false,
  };

  // componentDidMount() {
  //   console.log("[AuthModal] Did Mount");
  // }

  componentDidUpdate(prevProps, prevState) {
    if (
      prevProps.resetInputs !== this.props.resetInputs &&
      this.props.resetInputs
    ) {
      this.setState({
        ...this.state,
        controls: {
          ...this.state.controls,
          username: {
            ...this.state.controls.username,
            value: "",
          },
          password: {
            ...this.state.controls.password,
            value: "",
          },
        },
        validInput: true, // to remove error msg
      });
    }
    if (
      prevProps.loginError !== this.props.loginError &&
      this.props.loginError
    ) {
      this.setState({
        ...this.state,
        validInput: false,
      });
    }
    if (
      prevProps.isAuthenticated !== this.props.isAuthenticated &&
      this.props.isAuthenticated
    ) {
      this.props.onHideAuthModal();
    }
  }

  inputChangedHandler = event => {
    this.setState({
      ...this.state,
      controls: {
        ...this.state.controls,
        [event.target.name]: {
          ...this.state.controls[event.target.name],
          value: event.target.value,
          shouldValidate: true,
          valid: checkValidity(event.target.name, event.target.value),
        },
      },
    });
  };

  submitHandler = event => {
    event.preventDefault();
    if (
      !this.state.controls["username"].valid ||
      !this.state.controls["password"].valid
    ) {
      this.setState({
        ...this.state,
        validInput: false,
      });
    } else {
      this.setState({
        ...this.state,
        validInput: true,
      });
      this.props.onLogin(
        this.state.controls.username.value,
        this.state.controls.password.value
      );
    }
  };

  homeButtonClickedHandler = () =>  {
    this.props.onHideAuthModal();
    this.props.onResetAuthState();
    this.setState({...this.state, homeRedirect: true});
  }

  render() {
    const classes =
      this.props.viewportWidth < 600 ? mobileClasses : desktopClasses;

    let errorMessage = null;
    if (!this.state.validInput && !this.props.sessionExpired) {
      errorMessage = (
        <p style={{ color: "#fd5e53", margin: "5px 0" }}>
          Invalid username or password
        </p>
      );
    }
    const homeRedirect = <Redirect to="/" />;
    const authPrompt = this.props.sessionExpired
      ? <p className={classes.FormTitle}>Session expired <br /> Please log in</p>
      : <p className={classes.FormTitle}>Login</p>;

    return (
      <Modal show={this.props.show} modalClosed={this.props.modalClosed}>
         {this.state.homeRedirect && homeRedirect}
        <LoginLogo className={classes.LoginLogo} />
        {authPrompt}
        <form
          className={classes.AuthModal}
          onSubmit={this.submitHandler}
          onKeyDown={this.props.onKeyDown}
        >
          <div className={classes.Input}>
            <UserLogo className={classes.UserLogo} />
            <input
              className={classes.InputElement}
              type={this.state.controls["username"].type}
              placeholder={this.state.controls["username"].placeholder}
              name="username"
              value={this.state.controls["username"].value}
              autoComplete="off"
              onChange={this.inputChangedHandler}
            />
          </div>
          <div className={classes.Input}>
            <LockLogo className={classes.LockLogo} />
            <input
              className={classes.InputElement}
              type={this.state.controls["password"].type}
              placeholder={this.state.controls["password"].placeholder}
              name="password"
              value={this.state.controls["password"].value}
              onChange={this.inputChangedHandler}
            />
          </div>
          <button className={classes.SubmitButton}>
            {this.props.loading ? <Spinner ButtonStyle /> : "Login"}
          </button>

        </form>
        {this.props.sessionExpired &&
          <button className={classes.HomeButton} onClick={this.homeButtonClickedHandler}>
            Home
          </button>}
        {errorMessage}
      </Modal>
    );
  }
}

const mapStateToProps = state => {
  return {
    loginError: state.auth.error,
    isAuthenticated: state.auth.isAuthenticated,
    loading: state.auth.loading,
    viewportWidth: state.app.viewportWidth,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    onLogin: (username, password) => {
      dispatch(actions.login(username, password));
    },
    onHideAuthModal: () => {
      dispatch(actions.hideAuthModal());
    },
    onResetAuthState: () => {
      dispatch(actions.authFail());
    }
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AuthModal);

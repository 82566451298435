import axios from 'axios';
import { BACKEND_HOSTS, EXPIRY_KEYNAME, TOKEN_KEYNAME } from '../../appConfigs';
import { getTokenExpirationTimestamp } from '../../shared/utility';
import * as actionTypes from './actionTypes';

const host =
  BACKEND_HOSTS[
    process.env.NODE_ENV === 'development' ? 'development' : 'production'
  ];

export const authStart = () => {
  return {
    type: actionTypes.AUTH_START,
  };
};

export const authSuccess = (token, expiry) => {
  return {
    type: actionTypes.AUTH_SUCCESS,
    token: token,
    expiry: expiry,
  };
};

export const authFail = error => {
  return {
    type: actionTypes.AUTH_FAIL,
    error: error,
  };
};

export const authComplete = () => {
  return {
    type: actionTypes.AUTH_COMPLETE,
  };
};

export const setAuthRedirectPath = path => {
  return {
    type: actionTypes.SET_AUTH_REDIRECT_PATH,
    path: path,
  };
};

export const authCheckStart = () => {
  return {
    type: actionTypes.AUTH_CHECK_START,
  };
};

export const authCheckComplete = () => {
  return {
    type: actionTypes.AUTH_CHECK_COMPLETE,
  };
};

export const authLogoutStart = () => {
  return {
    type: actionTypes.AUTH_LOGOUT_START,
  };
};

export const authLogoutComplete = () => {
  return {
    type: actionTypes.AUTH_LOGOUT_COMPLETE,
  };
};

export const login = (username, password) => {
  return dispatch => {
    dispatch(authStart());
    const authData = {
      username: username,
      password: password,
    };
    let url = `${host}/api/auth/login`;
    axios
      .post(url, authData)
      .then(response => {
        const token = response.data.access_token;
        const expiry = getTokenExpirationTimestamp(response.data.expires_in);
        sessionStorage.setItem(TOKEN_KEYNAME, token);
        sessionStorage.setItem(EXPIRY_KEYNAME, expiry);
        dispatch(authSuccess(token, expiry));
      })
      .catch(error => {
        dispatch(authFail(error.response));
      });
  };
};

export const authCheckState = () => {
  return dispatch => {
    dispatch(authCheckStart());
    const url = `${host}/api/auth/check`;
    const token = sessionStorage.getItem(TOKEN_KEYNAME);
    const expiry = sessionStorage.getItem(EXPIRY_KEYNAME);
    const headers = { Authorization: `Bearer ${token}` };
    axios
      .get(url, { headers: headers })
      .then(response => {
        dispatch(authSuccess(token, expiry));
        dispatch(authCheckComplete());
      })
      .catch(error => {
        dispatch(authFail(error.response));
        dispatch(authCheckComplete());
      });
  };
};

export const logout = () => {
  return dispatch => {
    sessionStorage.removeItem(TOKEN_KEYNAME);
    sessionStorage.removeItem(EXPIRY_KEYNAME);
    dispatch(authLogoutComplete());
  };
};

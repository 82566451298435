import React from "react";
import classes from "./Spinner.module.css";

const spinner = props => (
  <div
    className={`${classes.loader}
    ${props.ButtonStyle && classes.ButtonStyle} 
    ${props.addClass}`}
  >
    Loading...
  </div>
);

export default spinner;

import React, { Component } from "react";
import ReactDOM from "react-dom";
import { connect } from "react-redux";
import { NavLink, Redirect } from "react-router-dom";

import classes from "./Toolbar.module.css";
import NavigationItems from "../../Navigation/NavigationItems/NavigationItems";
import HomeNavItem from "../NavigationItems/HomeNavItem/HomeNavItem";
import SideDrawer from "../../Navigation/SideDrawer/SideDrawer";
import MobileChartDropDown from "../DropDowns/MobileChart/MobileChartDropDown";
import Tooltip from "../../UI/Tooltip/Tooltip";
import Aux from "../../../hoc/Auxiliary/Auxiliary";
import * as actions from "../../../store/actions/index";
import HomeLogo from "../../Logos/HomeLogo";
import ChartMenuLogo from "../../Logos/ChartMenuLogo";
import ChartResetLogo from "../../Logos/ChartResetLogo";
import BurgerMenuLogo from "../../Logos/BurgerMenuLogo";
import CrossMenuLogo from "../../Logos/CrossMenuLogo";
import IndicatorLogo from "../../Logos/IndicatorLogo";
import ChartOptionsLogo from "../../Logos/ChartOptionsLogo";
import ChartLogo from "../../Logos/ChartLogo";

class Toolbar extends Component {
  state = {
    redirect: false,
    showTooltip: false,
    tooltipXLocation: 0,
    tooltipYLocation: 54,
    tooltipMessage: null,
  };

  chartMenuClickHandler = () => {
    if (this.props.dropdownShown) {
      this.props.onHideDropDown();
    } else {
      this.props.onShowDropDown();
    }
  };

  homeLogoClickHandler = () => {
    this.setState({
      ...this.state,
      redirect: true,
    });
  };

  timeframeSelectHandler = event => {
    const currentTimeframe = this.props.timeframe;
    const requestedTimeframe = event.target.innerText;

    if (requestedTimeframe !== currentTimeframe) {
      this.props.onChangeTimeframe(requestedTimeframe);
    }
  };

  chartResetHandler = () => {
    this.props.onResetChart(true);
  };

  burgerMenuClickHandler = () => {
    this.props.onToggleMenuIcon();
    if (this.props.sidedrawerShown) {
      this.props.onHideSideDrawer();
    } else {
      this.props.onShowSideDrawer();
    }
  };

  navClickedHandler = () => {
    if (!this.props.isAuthenticated) {
      this.props.onShowAuthModal();
    }
  };

  buttonHoverHandler = (event, tooltipMessage) => {
    const buttonRect = ReactDOM
      .findDOMNode(this[tooltipMessage])
      .getBoundingClientRect();
    const buttonXLoc = tooltipMessage === "Change Symbol"
      ? buttonRect.x + (buttonRect.width / 2) + 1
      : buttonRect.x;
    this.setState({
      ...this.state,
      showTooltip: true,
      tooltipXLocation: buttonXLoc,
      tooltipMessage,
    });
  }

  buttonOutHandler = () => {
    this.setState({ ...this.state, showTooltip: false });
  }

  render() {
    const burgerMenu = (
      <div onClick={this.burgerMenuClickHandler} className={classes.MenuIcon}>
        {this.props.menuIconClicked ? <CrossMenuLogo /> : <BurgerMenuLogo />}
      </div>
    );

    let toolbar = (
      <Aux>
        <HomeNavItem>Ladybrand</HomeNavItem>
        <NavigationItems isAuthenticated={this.props.isAuth} />
      </Aux>
    );

    let chartToolbar = (
      <Aux>
        {this.state.redirect && <Redirect to="/" />}
        <div className={classes.HomeLogo} onClick={this.homeLogoClickHandler}>
          <HomeLogo />
        </div>

        <div className={`${classes.Timeframes} ${classes.LeftTools}`}>
          <button
            onClick={this.timeframeSelectHandler}
            className={`${classes.Timeframe} ${
              this.props.timeframe === "5m" && classes.Active
            }`}
          >
            5m
          </button>
          <button
            onClick={this.timeframeSelectHandler}
            className={`${classes.Timeframe} ${
              this.props.timeframe === "15m" && classes.Active
            }`}
          >
            15m
          </button>
          <button
            onClick={this.timeframeSelectHandler}
            className={`${classes.Timeframe} ${
              this.props.timeframe === "1h" && classes.Active
            }`}
          >
            1h
          </button>
        </div>

        <div
          onClick={this.props.onShowSymbolDropdown}
          className={classes.CoinName}
          onMouseOver={e => this.buttonHoverHandler(e, "Change Symbol")}
          onMouseOut={this.buttonOutHandler}
          ref={elt => this["Change Symbol"] = elt}
        >
          <div className={classes.CoinNameChild}>{this.props.symbol}</div>
        </div>

        <div className={`${classes.Timeframes} ${classes.RightTools}`}>
          <button
            onClick={this.chartResetHandler}
            className={classes.Timeframe}
            onMouseOver={e => this.buttonHoverHandler(e, "Reset Chart")}
            onMouseOut={this.buttonOutHandler}
            ref={elt => this["Reset Chart"] = elt}
          >
            <ChartResetLogo />
          </button>
          <button
            onClick={this.props.onShowIndicatorsDropdown}
            className={`${classes.Timeframe} ${classes.Indicators}`}
            onMouseOver={e => this.buttonHoverHandler(e, "Indicators")}
            onMouseOut={this.buttonOutHandler}
            ref={elt => this["Indicators"] = elt}
          >
            <IndicatorLogo />
          </button>
        </div>
        <Tooltip
          show={this.state.showTooltip}
          xLocation={this.state.tooltipXLocation}
          yLocation={this.state.tooltipYLocation}
        >
          {this.state.tooltipMessage}
        </Tooltip>

        <div
          className={`${classes.ChartMenuLogo} ${
            this.props.rotateMenuIcon && classes.ChartMenuLogoRotate
          }`}
          onClick={this.chartMenuClickHandler}
        >
          <ChartMenuLogo />
        </div>
      </Aux>
    );

    if (this.props.viewportWidth < 600) {
      // Mobile View
      toolbar = (
        <Aux>
          {burgerMenu}
          <HomeNavItem>Ladybrand</HomeNavItem>
          <NavLink to="/chart" onClick={this.navClickedHandler}>
            <div className={classes.ChartLogo}>
              <ChartLogo />
            </div>
          </NavLink>
        </Aux>
      );

      chartToolbar = (
        <Aux>
          {burgerMenu}
          <div style={{ position: "relative" }}>
            <MobileChartDropDown
              timeframeSelect={this.timeframeSelectHandler}
            />
            <div className={classes.CoinName}>
              <div className={classes.CoinNameChild}>{this.props.symbol}</div>
            </div>
            <div
              className={`${classes.Timeframes} ${classes.ChartReset} ${classes.Mobile}`}
            >
              <button
                onClick={this.chartResetHandler}
                className={`${classes.Timeframe} ${classes.ChartResetChild}`}
              >
                <ChartResetLogo />
              </button>
            </div>
          </div>
          <div className={classes.Options}>
            <ChartOptionsLogo />
          </div>
        </Aux>
      );
    }

    return (
      <Aux>
        {this.props.viewportWidth < 600 && <SideDrawer />}
        <header
          className={`${classes.Toolbar} ${
            this.props.Chart && classes.ChartHeader
          }`}
        >
          {this.props.Chart ? chartToolbar : toolbar}
        </header>
      </Aux>
    );
  }
}

const mapStateToProps = state => {
  return {
    isAuthenticated: state.auth.isAuthenticated,
    dropdownShown: state.dropdown.dropdownShown,
    rotateMenuIcon: state.dropdown.rotateMenuIcon,
    symbol: state.chart.symbol,
    timeframe: state.chart.timeframe,
    viewportWidth: state.app.viewportWidth,
    menuIconClicked: state.toolbar.menuIconClicked,
    sidedrawerShown: state.sidedrawer.sidedrawerShown,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    onShowAuthModal: () => {
      dispatch(actions.showAuthModal());
    },
    onShowDropDown: () => {
      dispatch(actions.showDropDown());
    },
    onHideDropDown: () => {
      dispatch(actions.hideDropDown());
    },
    onChangeTimeframe: timeframe => {
      dispatch(actions.changeTimeframe(timeframe));
    },
    onResetChart: reset => {
      dispatch(actions.resetChart(reset));
    },
    onToggleMenuIcon: () => {
      dispatch(actions.toggleMenuIcon());
    },
    onShowSideDrawer: () => {
      dispatch(actions.showSideDrawer());
    },
    onHideSideDrawer: () => {
      dispatch(actions.hideSideDrawer());
    },
    onShowSymbolDropdown: () => {
      dispatch(actions.showSymbolsDropdown());
    },
    onShowIndicatorsDropdown: () => {
      dispatch(actions.showIndicatorsDropdown());
    }
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Toolbar);

import React from "react";
import { timeFormat } from "d3-time-format";
import { Chart } from "react-stockcharts";
import { LineSeries } from "react-stockcharts/lib/series";
import { XAxis, YAxis } from "react-stockcharts/lib/axes";
import { SingleValueTooltip } from "react-stockcharts/lib/tooltip";
import { EdgeIndicator, MouseCoordinateX, MouseCoordinateY } from "react-stockcharts/lib/coordinates";

import { getVolumeKeyName } from "../utils/helpers";
import { chartDimensions, fontFamily } from "../utils/viewportConfigs";
import { formatCurrency } from "../../../shared/utility";


const lineChart = ({ id, volumeType, height, origin, yLabel, viewportWidth, showLast }) => {
  const volume = getVolumeKeyName(volumeType.split(' Diff')[0]);
  const yExtents = volumeType.search("Diff") !== -1
    ? d => d[volume.buy] - d[volume.sell]
    : [d => d[volume]];
  const yAccessor = volumeType.search("Diff") !== -1
    ? d => d[volume.buy] - d[volume.sell]
    : d => d[volume];
  const chartPadding = {top: 10, bottom: 10};
  const yOrient = volumeType.search("Diff") !== -1 ? "left" : "right";
  yLabel = volumeType.search("Diff") !== -1 ? "Diff" : yLabel;
  const { xAxis, yAxis, tooltip, edgeIndicator, indicator } = chartDimensions(viewportWidth);

  return (
    <Chart
      id={id}
      key={id}
      height={height}
      yExtents={yExtents}
      padding={chartPadding}
      origin={origin}
    >
      {volumeType.search("Diff") === -1 &&
        <XAxis
          axisAt="bottom"
          orient="bottom"
          showTicks={showLast}
          ticks={xAxis.ticks}
          outerTickSize={0}
          strokeWidth={1}
          stroke={showLast ? "#bbbfca" : "#7F7F80"}
          tickStroke={showLast ? "#bbbfca" : "#e8e8e8"}
          fontSize={xAxis.fontSize}
          fontFamily={fontFamily}
        />}
      <YAxis
        axisAt={yOrient}
        orient={yOrient}
        ticks={3}
        stroke="#7F7F80"
        tickStroke="#bbbfca"
        fontSize={yAxis.fontSize}
        tickFormat={volumeType.search("Diff") !== -1 ? indicator.mouseY.tickFormat: formatCurrency}
        fontFamily={fontFamily}
      />
      {volumeType.search("24h") !== -1 &&
        <YAxis
          axisAt="left"
          orient="left"
          ticks={0}
          stroke="#7F7F80"
          tickStroke="#bbbfca"
        />}
      {showLast &&
        <MouseCoordinateX
          at="bottom"
          orient="bottom"
          displayFormat={timeFormat("%Y-%m-%d %H:%M:%S")}
          fontSize={xAxis.fontSize}
        />}
      <MouseCoordinateY
        at={yOrient}
        orient={yOrient}
        displayFormat={formatCurrency}
        fontSize={yAxis.fontSize}
        arrowWidth={0}
        rectHeight={18}
      />

      <LineSeries
        yAccessor={yAccessor}
        stroke={yLabel === "Diff" ? "#FBC02D" : "#01C5C4"}
        strokeWidth={1.5}
      />

      {volumeType === "24hQuoteVolume" &&
        <EdgeIndicator
          itemType="last"
          orient={yOrient}
          edgeAt={yOrient}
          displayFormat={formatCurrency}
          yAccessor={yAccessor}
          fontSize={yAxis.fontSize}
          yAxisPad={edgeIndicator.yAxisPad}
          arrowWidth={edgeIndicator.arrowWidth}
          rectHeight={edgeIndicator.rectHeight}
          fill="#009d9c"
        />}
      <SingleValueTooltip
        onClick={e => console.log(e)}
        origin={yLabel === "Diff" ? [120, 15] : [5, 15]}
        yLabel={yLabel}
        yAccessor={yAccessor}
        yDisplayFormat={formatCurrency}
        labelFill="#e8e8e8"
        valueFill={yLabel === "Diff" ? "#FBC02D" : "#12CAD6"}
        fontSize={tooltip.fontSize}
        fontFamily={fontFamily}
      />
    </Chart>
  );
}

export default lineChart;
